import marilenePicture from '../../assets/marilene-picture-color.png';
import customLobbyBotLogo from '../../assets/custom-lobby-bot-logo.webp';
import naturokosLogo from '../../assets/naturokos-logo.webp';
import { servicesElementsData } from '../../constants/showcase-website.constants';
import Button from '../atoms/button/button';
import AnimatedLogo from '../molecules/animated-logo';
import Card from '../molecules/card';

import mintfarbenLogoDarkGrey from '../../assets/mintfarben.studio-logo-dark-grey.png';
import mintfarbenLogoMintgreen from '../../assets/mintfarben.studio-logo-mintgreen.png';
import mintfarbenLogoYellow from '../../assets/mintfarben.studio-logo-yellow.png';

import adsBlue from '../../assets/ads-blue-google.png';
import adsLightBlue from '../../assets/ads-light-blue-google.png';
import adsVeryLightBeige from '../../assets/ads-very-light-beige.png';

import websiteDarkGrey from '../../assets/website-dark-grey-batman.png';
import websiteLightGrey from '../../assets/website-light-grey.png';
import websiteVeryLightGrey from '../../assets/website-very-light-grey.png';

interface HomepageOrganismProps {
  calendlyTag: string | JSX.Element;
  mailContactContent: string | JSX.Element;
  hoveredCardId: number | null;
  handleMouseEnter: (value: number) => void;
  handleMouseLeave: () => void;
  handleButtonMouseEnter: (value: number) => void;
  handleButtonMouseLeave: () => void;
}

const HomepageOrganism = (props: HomepageOrganismProps) => {
  const {
    calendlyTag,
    mailContactContent,
    hoveredCardId,
    handleMouseEnter,
    handleMouseLeave,
    handleButtonMouseEnter,
    handleButtonMouseLeave,
  } = props;

  const logosData = [
    {
      src: mintfarbenLogoYellow,
      alt: 'mintfarben logo yellow',
      title: "mintfarben. Studio's logo",
      className: 'mintfarben-logo',
    },
    {
      src: mintfarbenLogoMintgreen,
      alt: 'mintfarben logo mintgreen',
      title: "mintfarben. Studio's logo",
      className: 'mintfarben-logo',
    },
    {
      src: mintfarbenLogoDarkGrey,
      alt: 'mintfarben logo dark grey',
      title: "mintfarben. Studio's logo",
      className: 'mintfarben-logo',
    },
  ];

  const adsData = [
    {
      src: adsVeryLightBeige,
      alt: 'Ads text in very light blue color',
      title: '',
      className: 'ads-badge montage-badge',
    },
    {
      src: adsLightBlue,
      alt: 'Ads text in light blue color',
      title: '',
      className: 'ads-badge montage-badge',
    },
    {
      src: adsBlue,
      alt: 'Ads text in blue color',
      title: '',
      className: 'ads-badge montage-badge',
    },
  ];

  const websiteData = [
    {
      src: websiteVeryLightGrey,
      alt: 'Website text in very light grey color',
      title: '',
      className: 'website-badge montage-badge',
    },
    {
      src: websiteLightGrey,
      alt: 'Website text in light grey color',
      title: '',
      className: 'website-badge montage-badge',
    },
    {
      src: websiteDarkGrey,
      alt: 'Website text in dark grey color',
      title: '',
      className: 'website-badge montage-badge',
    },
  ];

  return (
    <main id="homepage-main">
      <section id="studio" className="section-standard-padding-left-right">
        <div className="services-montages-container montage-changing-background">
          <a href="#services">
            <div className="services-montage ads-montage">
              <div className="animation-container">
                <AnimatedLogo imagesData={adsData} />
              </div>
            </div>

            <div className="services-montage website-montage">
              <div className="animation-container">
                <AnimatedLogo imagesData={websiteData} />
              </div>
            </div>
          </a>
        </div>

        <div className="home-content">
          <p>
            <span className="mintgreen-underline">
              <b>Gagnez de nouveaux clients</b>
            </span>{' '}
            avec{' '}
            <span className="mintgreen-underline">
              <b>un site bien référencé sur Google</b>
            </span>{' '}
            et{' '}
            <span className="mintgreen-underline">
              <b>un copywriting impactant</b>
            </span>{' '}
            grâce aux stratégies utilisées par <b>les meilleures entreprises</b>
            .
          </p>

          <div className="button-calendly-container">
            <Button
              className="plain-button calendly-button"
              content={calendlyTag}
              onClick={() => {}}
            />
          </div>
        </div>
      </section>

      <section id="services" className="section-standard-padding-left-right">
        <div>
          <h2>NOTRE EXPERTISE</h2>

          <div className="cards-services-container">
            {servicesElementsData.map((service) => {
              return (
                <div key={service.id} className="single-card-container">
                  <Card
                    content={service}
                    onMouseEnter={() => handleMouseEnter(service.id)}
                    onMouseLeave={handleMouseLeave}
                    isHovered={hoveredCardId === service.id}
                  />

                  {hoveredCardId === service.id && (
                    <div
                      className="hidden-button"
                      onMouseEnter={() => handleButtonMouseEnter(service.id)}
                      onMouseLeave={handleButtonMouseLeave}
                    >
                      <Button
                        className={`plain-button calendly-button ${
                          hoveredCardId ? 'visible' : ''
                        }`}
                        content={calendlyTag}
                        onClick={() => {}}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section id="clients" className="section-standard-padding-left-right">
        <div>
          <h2>CE QUE DISENT MES CLIENTS</h2>
          <div className="customers-reviews-container">
            <div className="card-customer-review-container">
              <div>
                <a href="https://customlobbybot.com" target="_blank">
                  <div
                    id="custom-lobby-bot-background-image"
                    className="customer-review-image"
                  />
                </a>
              </div>

              <div className="customer-review-description">
                <a href="https://customlobbybot.com" target="_blank">
                  <img
                    className="clients-logo"
                    src={customLobbyBotLogo}
                    alt="Custom Lobby Bot Logo"
                    title=""
                  />
                </a>
                <div>
                  <h3>Site Internet de Custom Lobby Bot</h3>

                  <cite>
                    "Très satisfait de l'accompagnement et des conseils de
                    Marilène. Le rendu du site fait très professionnel et le
                    design matche parfaitement à mon produit."
                    <br />- Bellay
                  </cite>
                </div>
              </div>
            </div>

            <div className="card-customer-review-container">
              <div>
                <a href="https://www.naturokos.com/" target="_blank">
                  <div
                    id="naturokos-background-image"
                    className="customer-review-image"
                  />
                </a>
              </div>

              <div className="customer-review-description">
                <a href="https://www.naturokos.com/" target="_blank">
                  <img
                    className="clients-logo"
                    src={naturokosLogo}
                    alt="Custom Lobby Logo"
                    title=""
                  />
                </a>
                <div>
                  <h3>Audit site & SEO pour NaturoKos</h3>

                  <cite>
                    "Ayant peu de connaissances en informatique et en marketing,
                    j'avais besoin d'infos claires et faciles à mettre en place.
                    Marilène a su m'expliquer simplement comment l'améliorer et
                    comment obtenir une meilleure visibilité."
                    <br />- Anne Lagière
                  </cite>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="mintfarben" className="section-standard-padding-left-right">
        <div>
          <h2>UN ACCOMPAGNEMENT PERSONNALISÉ</h2>

          <div id="mintfarben-container">
            <img
              className="marilene-color-picture"
              src={marilenePicture}
              alt="Marilene's picture"
              title=""
            />
            <div className="mintfarben-content">
              <p>
                <b>mintfarben. Studio</b> est une <b>agence de webmarketing</b>{' '}
                qui <span className="mintgreen-underline">vous accompagne</span>{' '}
                et{' '}
                <span className="mintgreen-underline">
                  vous conseille à chaque étape
                </span>{' '}
                de la création de votre stratégie de marketing digital et la
                conception de votre site Internet.
              </p>

              <p>
                Derrière <b>mintfarben. Studio</b> se cache{' '}
                <b>Marilène Khieu</b> :
              </p>

              <cite>
                "Hello, je suis Marilène =).<br></br> À l’écoute, sensible,
                dotée d'un fort esprit d'équipe et avec une profonde envie
                d’aider et de conseiller, je suis résolument tournée vers
                l'humain.
                <br></br>Avec plus de 10 ans d'expérience en entreprise et en
                start-up à Paris et à Berlin, j'ai acquis des compétences clés
                aux postes de Sales, Customer Success Manager et en
                développement web.<br></br>Mon objectif ? Vous aider à attirer
                durablement de nouveaux clients et à booster votre visibilité en
                ligne."
              </cite>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="section-standard-padding-left-right">
        <div className="contact-container">
          <div id="logos-container">
            <AnimatedLogo imagesData={logosData} />
          </div>

          <div className="contact-content section-width-80">
            <div>
              <p>
                Vous aimeriez optimiser votre visibilité en ligne et{' '}
                <b>vous avez besoin de conseils</b> ?
              </p>
              <p>
                Vous aimeriez développer votre chiffre d’affaires mais{' '}
                <b>vous ne savez pas comment faire</b> ?
              </p>
              <p>
                <span className="mintgreen-underline">
                  Parlons-en dès maintenant
                </span>{' '}
                =).
              </p>
            </div>

            <div className="button-calendly-container button-contact-container">
              <Button
                className="plain-button calendly-button"
                content={calendlyTag}
                onClick={() => {}}
              />
            </div>

            <div className="contact-via-mail-container">
              <Button
                className="contact-via-mail"
                content={mailContactContent}
                onClick={() => {}}
              />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default HomepageOrganism;
