import './footer.scss';
import {
  DefaultFooterElements,
  FooterElementCategory,
} from '../../interfaces/showcase-website-interfaces';
import Icon from '../atoms/icon/icon';
import { Link } from 'react-router-dom';

interface FooterProps {
  footerElementsData: Array<DefaultFooterElements>;
}

const Footer = (props: FooterProps) => {
  const { footerElementsData } = props;

  const elementsByCategory: Record<
    FooterElementCategory,
    Array<DefaultFooterElements>
  > = {
    basic: footerElementsData.filter((element) => element.category === 'basic'),
    'social-networks': footerElementsData.filter(
      (element) => element.category === 'social-networks'
    ),
    settings: footerElementsData.filter(
      (element) => element.category === 'settings'
    ),
  };

  return (
    <footer className="section-standard-width">
      <ul>
        {elementsByCategory['basic'].map((element) => {
          if (element.isActive)
            return (
              <li key={element.id}>
                {element.internalLink ? (
                  <Link to={element.internalLink} className={element.className}>
                    <span>
                      <Icon className={element.iconClassname} />
                      {element.content}
                    </span>
                  </Link>
                ) : (
                  <a
                    href={element.externalLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={element.className}
                  >
                    <span>
                      <Icon className={element.iconClassname} />
                      {element.content}
                    </span>
                  </a>
                )}
              </li>
            );
        })}

        {/* <li>
          {elementsByCategory['social-networks'].map((element) => {
            if (element.isActive)
              return (
                <div key={element.id} className={element.category}>
                  {element.internalLink ? (
                    <Link
                      to={element.internalLink}
                      className={element.className}
                    >
                      <span>
                        <Icon className={element.iconClassname} />
                        {element.content}
                      </span>
                    </Link>
                  ) : (
                    <a
                      href={element.externalLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={element.className}
                    >
                      <span>
                        <Icon className={element.iconClassname} />
                        {element.content}
                      </span>
                    </a>
                  )}
                </div>
              );
          })}
        </li> */}
      </ul>
    </footer>
  );
};

export default Footer;
