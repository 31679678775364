import './list-order-template.scss';
import React from 'react';
import i18next from 'i18next';
import { ButtonProps } from '../components/atoms/button/button';
import ListOrganism from '../components/organisms/list-organism';
import {
  addInput,
  deleteAllInputs,
  orderInputs,
} from '../helpers/list-order/list-order-action-helper';
import { InputData } from '../interfaces/list-order-interfaces';
import { defaultInputNumberData } from '../constants/list-order-constants';
import { getTranslation } from '../helpers/common-helpers';

interface ListOrderTemplateProps {
  inputs: Array<number>;
  setInputs: (value: Array<number>) => void;
}

const ListOrderTemplate = (props: ListOrderTemplateProps) => {
  const buttonsData: Array<ButtonProps> = [
    {
      content: getTranslation('translation', 'button.add'),
      className: 'plain-button list-order-buttons',
      onClick: () => {
        addInput(props.inputs, props.setInputs);
      },
      disabled: false,
    },
    {
      content: getTranslation('translation', 'button.order'),
      className: 'plain-button list-order-buttons',
      onClick: () => {
        orderInputs(props.inputs, props.setInputs);
      },
      disabled: false,
    },
    {
      content: getTranslation('translation', 'button.delete-all'),
      className: 'plain-button list-order-buttons',
      onClick: () => {
        deleteAllInputs(props.setInputs);
      },
      disabled: false,
    },
  ];

  return (
    <div id="numbers-list-body">
      <header>
        <h1>{getTranslation('translation', 'main-title.random-numbers')}</h1>
      </header>

      <main id="numbers-list-container">
        <ListOrganism
          buttonsData={buttonsData}
          inputsData={props.inputs.map((input, index): InputData => {
            return {
              ...defaultInputNumberData,
              id: `input-${index}`, // template literals here => gives value of 'i' variable
              className: 'input-number list-order-inputs',
              value: input,
              onChange: (e: any) => {
                const newInputs = [...props.inputs];

                let updatedValue = e.target.value ?? 0; // if e.target.value equals null or undefined, then affects 0 as a value

                updatedValue = Math.max(0, updatedValue);
                updatedValue = Math.min(99, updatedValue);

                newInputs[index] = updatedValue;

                props.setInputs(newInputs);
              },
            };
          })}
          setInputs={props.setInputs}
        />
      </main>
    </div>
  );
};

export default ListOrderTemplate;

// Le template s'occupe d'afficher les data
