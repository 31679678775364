import './dices-list.scss';
import {
  dotPositionMatrix,
  numberOfRounds,
  numberOfThrowns,
} from '../../../constants/games-constants';
import {
  sortAscendingIcon,
  twoDicesRollingIcon,
} from '../../../constants/icons-constants';
import { DefaultScoring } from '../../../interfaces/games-interfaces';
import { CurrentDicesScoringProps } from '../../../templates/yahtzee-home-template';
import Button from '../../atoms/button/button';
import Dice from '../../atoms/dice/dice';
import { getTranslation } from '../../../helpers/common-helpers';
import { getSortedDicesResults } from '../../../helpers/games/games-helpers';
import { useEffect } from 'react';

export interface RollDicesProps {
  rollDices: (value: Array<DefaultScoring>) => void;
}

export interface PickDicesProps {
  pickDicesToReroll: (value: number) => void;
}

export interface RollDiceActionsProps {
  rollDiceActions: () => void;
}

export interface RollNumberProps {
  rollNumber: number;
  currentPlayingPlayerId: number;
}

export interface RoundNumberDicesListProps {
  roundNumber: number;
}

export interface MaxNumberOfThrownsProps {
  maxNumberOfThrowns: number;
}

const DicesList = (
  props: CurrentDicesScoringProps &
    RollDicesProps &
    PickDicesProps &
    RollDiceActionsProps &
    RollNumberProps &
    RoundNumberDicesListProps &
    MaxNumberOfThrownsProps
) => {
  const {
    currentDicesScoring,
    setCurrentDicesScoring,
    pickDicesToReroll,
    rollDiceActions,
    rollNumber,
    currentPlayingPlayerId,
    roundNumber,
    maxNumberOfThrowns,
  } = props;

  const sortDices = (currentDicesScoring: Array<DefaultScoring>) => {
    const copyDicesScoring = [...currentDicesScoring];
    const ascendingSortedDices = copyDicesScoring.sort(
      (a, b) => a.score - b.score
    );
    setCurrentDicesScoring(ascendingSortedDices);
  };

  return (
    <div id="dices-roll-buttons-container">
      <div className="dices-with-order-btn-container">
        <div id="dices-container">
          {currentDicesScoring.map((dice, index) => (
            <div
              key={`dice-${index}`}
              onClick={
                rollNumber > 0 && rollNumber < maxNumberOfThrowns
                  ? () => pickDicesToReroll(index)
                  : () => {}
              }
            >
              <Dice
                dotsPositionArray={dotPositionMatrix[dice.score]}
                className={
                  dice.isSelected === true
                    ? 'selected-dices-to-reroll-container roll-dices-animation'
                    : ''
                }
                rollNumber={rollNumber}
                maxNumberOfThrowns={maxNumberOfThrowns}
              />
            </div>
          ))}
        </div>

        <Button
          className="plain-button sort-button"
          content={<i className={sortAscendingIcon} />}
          onClick={() => sortDices(currentDicesScoring)}
        />
      </div>

      <Button
        className={
          rollNumber === numberOfThrowns || roundNumber >= numberOfRounds + 1
            ? 'plain-button roll-dices-button roll-dices-button-disabled'
            : 'plain-button roll-dices-button'
        }
        content={
          <>
            ROLL <i className={twoDicesRollingIcon} />
          </>
        }
        onClick={() => rollDiceActions()}
        disabled={
          (rollNumber === numberOfThrowns && true) ||
          (roundNumber === numberOfRounds + 1 && true)
        }
      />

      <div className="small-dices-container">
        {currentDicesScoring.map(
          (dice, index) =>
            dice.isSelected === true && (
              <div
                key={`mini-dice-${index}`}
                onClick={() => pickDicesToReroll(index)}
              >
                <Dice
                  dotsPositionArray={dotPositionMatrix[dice.score]}
                  className="small-dices-to-reroll"
                  rollNumber={rollNumber}
                  maxNumberOfThrowns={maxNumberOfThrowns}
                />
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default DicesList;
