import './homepage.scss';
import { navbarElementsData } from '../constants/showcase-website.constants';
import { useState } from 'react';
import { DefaultNavbarElements } from '../interfaces/showcase-website-interfaces';
import Icon from '../components/atoms/icon/icon';
import LayoutTemplate from '../templates/layout-template';
import { Link, useNavigate } from 'react-router-dom';

interface LayoutPageProps {
  children: JSX.Element;
}

const LayoutPage = (props: LayoutPageProps) => {
  const { children } = props;

  const navigate = useNavigate();

  const [navItemIdClicked, setNavItemIdClicked] =
    useState<Array<DefaultNavbarElements>>(navbarElementsData);

  const handleLogoClick = () => {
    navigate('/');
  };

  const logoContent = (
    <a
      href="/#studio"
      onClick={handleLogoClick}
      className="mintfarben-studio-logo"
    >
      mintfarben. <span>Studio</span>
    </a>
  );

  return (
    <LayoutTemplate
      logoContent={logoContent}
      navItemIdClicked={navItemIdClicked}
      setNavItemIdClicked={setNavItemIdClicked}
      children={children}
    />
  );
};

export default LayoutPage;
