import './homepage.scss';
import { useState } from 'react';
import Icon from '../components/atoms/icon/icon';
import HomeTemplate from '../templates/home-template';

const Homepage = () => {
  const [hoveredCardId, setHoveredCardId] = useState<number | null>(null);
  const [isButtonHovered, setIsButtonHovered] = useState(false);

  const handleMouseEnter = (cardId: number) => {
    setHoveredCardId(cardId);
  };

  const handleMouseLeave = () => {
    setHoveredCardId(null);
    setIsButtonHovered(false);
  };

  const handleButtonMouseEnter = (cardId: number) => {
    setHoveredCardId(cardId);
    setIsButtonHovered(true);
  };

  const handleButtonMouseLeave = () => {
    setIsButtonHovered(false);
  };

  const calendlyTag = (
    <a href="https://calendly.com/mintfarben-studio-marilene/30min">
      <span>Je réserve un appel gratuit</span>
    </a>
  );

  const mailContactContent = (
    <a href="mailto:mintfarben.studio@gmail.com">
      Je préfère envoyer un e-mail{'  '}
      <Icon className="fa-regular fa-paper-plane" />
    </a>
  );

  return (
    <HomeTemplate
      calendlyTag={calendlyTag}
      mailContactContent={mailContactContent}
      hoveredCardId={hoveredCardId}
      handleMouseEnter={handleMouseEnter}
      handleMouseLeave={handleMouseLeave}
      handleButtonMouseEnter={handleButtonMouseEnter}
      handleButtonMouseLeave={handleButtonMouseLeave}
    />
  );
};

export default Homepage;
