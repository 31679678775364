import React, { useContext, useState } from 'react';
import ListOrderTemplate from '../templates/list-order-template';
import { GlobalContext } from '../contexts/global-context';

const ListOrderPage = () => {
  const globalContext = useContext(GlobalContext);
  const [inputs, setInputs] = useState<Array<number>>([]);

  if (globalContext.isLoading) return <div>Loading...</div>;

  return <ListOrderTemplate inputs={inputs} setInputs={setInputs} />;
};

export default ListOrderPage;

// La page contient un seul template.
// Input + state gérés ici
// Contient la donnée et les fonctions pour mettre à jour les données
