export const arrayLeftIcon = "fa-solid fa-arrow-left-long";
export const closeIcon = "fa-solid fa-xmark";
export const sortIcon = "fa-solid fa-sort";
export const sortAscendingIcon = "fa-solid fa-arrow-down-short-wide";

export const trashIcon = "fa-solid fa-trash";

export const twoDicesRollingIcon = "fa-solid fa-dice";
export const diceOneIcon = "fa-solid fa-dice-one";
export const diceTwoIcon = "fa-solid fa-dice-two";
export const diceThreeIcon = "fa-solid fa-dice-three";
export const diceFourIcon = "fa-solid fa-dice-four";
export const diceFiveIcon = "fa-solid fa-dice-five";
export const diceSixIcon = "fa-solid fa-dice-six";

export const twoHandsIcon = "fa-solid fa-hands-asl-interpreting";
export const twoHandsApplaudingIcon = "fa-solid fa-hands-clapping";
export const handSpokeIcon = "fa-regular fa-hand-spock";
export const regularHandIcon = "fa-regular fa-hand";

export const handPaper = "fa-solid fa-hand"
export const handScissors = "fa-solid fa-hand-scissors";
export const handStone = "fa-solid fa-hand-back-fist";

export const moonFilledIcon = "fa-solid fa-moon";
export const lightBulbIcon = "fa-regular fa-lightbulb";
export const rocketIcon = "fa-solid fa-rocket";
export const pencilIcon = "fa-solid fa-pencil";
export const walkieTalkieIcon = "fa-solid fa-walkie-talkie";

export const hippoIcon = "fa-solid fa-hippo";

export const smileyFilledIcon = "fa-solid fa-face-smile";
export const smileyNotFilledIcon = "fa-regular fa-face-smile";

export const laptopIcon = "fa-solid fa-laptop-code";
export const increasingChartLineIcon = "fa-solid fa-chart-line";
export const paperPlaneIcon = "fa-regular fa-paper-plane";

export const instagramIcon = "fa-brands fa-instagram";
export const linkedinIcon = "fa-brands fa-linkedin-in";