import { createContext } from "react";

interface GlobalContextData {
  isLoading: boolean,
  setIsLoading: (value: boolean) => void
}

export const GlobalContextDefaultValue:GlobalContextData = {
  isLoading: false,
  setIsLoading: () => {}
}


export const GlobalContext = createContext(GlobalContextDefaultValue);