import { numberOfDices, numberOfRounds, numberOfThrowns } from '../../constants/games-constants';
import {
  CombinationInfos,
  CombinationNames,
  DefaultRepeatedNumber,
  DefaultScoring,
  DefaultTotalScoreOfAllPlayers,
  GameConfig,
  GameScoring,
} from '../../interfaces/games-interfaces';

// export const createDefaultCombinationsArray = (): Record<
//   CombinationNames,
//   CombinationInfos
// > => {
//   return {
//     Ones: {
//       id: 1,
//       name: 'Ones',
//       score: 0,
//       isAssigned: false,
//       part: 'one',
//     },
//     Twos: {
//       id: 2,
//       name: 'Twos',
//       score: 0,
//       isAssigned: false,
//       part: 'one',
//     },
//     Threes: {
//       id: 3,
//       name: 'Threes',
//       score: 0,
//       isAssigned: false,
//       part: 'one',
//     },
//     Fours: {
//       id: 4,
//       name: 'Fours',
//       score: 0,
//       isAssigned: false,
//       part: 'one',
//     },
//     Fives: {
//       id: 5,
//       name: 'Fives',
//       score: 0,
//       isAssigned: false,
//       part: 'one',
//     },
//     Sixes: {
//       id: 6,
//       name: 'Sixes',
//       score: 0,
//       isAssigned: false,
//       part: 'one',
//     },
//     ThreeOfAKind: {
//       id: 7,
//       name: 'ThreeOfAKind',
//       score: 0,
//       isAssigned: false,
//       part: 'two',
//     },
//     FourOfAKind: {
//       id: 8,
//       name: 'FourOfAKind',
//       score: 0,
//       isAssigned: false,
//       part: 'two',
//     },
//     SmallStraight: {
//       id: 9,
//       name: 'SmallStraight',
//       score: 0,
//       isAssigned: false,
//       part: 'two',
//     },
//     LargeStraight: {
//       id: 10,
//       name: 'LargeStraight',
//       score: 0,
//       isAssigned: false,
//       part: 'two',
//     },
//     FullHouse: {
//       id: 11,
//       name: 'FullHouse',
//       score: 0,
//       isAssigned: false,
//       part: 'two',
//     },
//     Chance: {
//       id: 12,
//       name: 'Chance',
//       score: 0,
//       isAssigned: false,
//       part: 'two',
//     },
//     Yahtzee: {
//       id: 13,
//       name: 'Yahtzee',
//       score: 0,
//       isAssigned: false,
//       part: 'two',
//     },
//     Bonus: {
//       id: 14,
//       name: 'Bonus',
//       score: 0,
//       isAssigned: false,
//       part: '0',
//     },
//     SubtotalPartOne: {
//       id: 15,
//       name: 'SubtotalPartOne',
//       score: 0,
//       isAssigned: false,
//       part: '0',
//     },
//     SubtotalPartTwo: {
//       id: 16,
//       name: 'SubtotalPartTwo',
//       score: 0,
//       isAssigned: false,
//       part: '0',
//     },
//     Total: {
//       id: 17,
//       name: 'Total',
//       score: 0,
//       isAssigned: false,
//       part: 'total',
//     },
//   };
// };

export const createDefaultGameConfig = (): GameConfig => {
  return {
    numberOfPlayers: 0,
    players: [],
    minPlayer: 2,
    maxPlayer: 4,
    maxNumberOfDices: numberOfDices,
    maxNumberOfThrowns: numberOfThrowns,
    maxNumberOfTurns: numberOfRounds,
  }
};

export const getSortedDicesResults = (
  currentScoring: Array<DefaultScoring>
) => {
  let sortedDicesScoring: Array<number> = [];

  currentScoring.map((dice) => sortedDicesScoring.push(dice.score));

  return sortedDicesScoring.sort();
};

export const getMaxNumberInArray = (array: Array<number>) => {
  const getMaxNumber = array.reduce(
    (accumulator, currentValue) =>
      accumulator < currentValue ? currentValue : accumulator,
    0
  );

  return getMaxNumber;
};

export const calculateScoreCombination = (
  calculationFunction: Function,
  diceResultsArray: Array<number>,
  number: number
) => {
  const result = calculationFunction(diceResultsArray, number);
  return result;
};

export const getSumArrayNumbers = (array: Array<number>) =>
  array.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

export const getSumOfIdenticalNumbers = (
  array: Array<number>,
  number: number
) => {
  const filteredArray = array.filter((value) => value === number);
  return getSumArrayNumbers(filteredArray);
};

export const getScoreOfSpecialCombinations = (
  array: Array<number>,
  numberOfRepetition: number,
  combinationName: CombinationNames | undefined = undefined
) => {
  let finalScore: number = 0;
  let mostRepeatedNumbersArray: Array<DefaultRepeatedNumber> = [];

  for (let i = 0; i < array.length; i++) {
    const currentCounterObject = mostRepeatedNumbersArray.find(
      (number) => number.value === array[i]
    );

    if (currentCounterObject === undefined) {
      mostRepeatedNumbersArray.push({ value: array[i], counter: 1 });
      continue; // ends the current loop and immediately goes to the next loop without reading the code below
    }

    currentCounterObject.counter += 1;
  }

  const highestCounterObject = mostRepeatedNumbersArray.reduce(
    (accumulator, currentCounter) =>
      accumulator && accumulator.counter > currentCounter.counter
        ? accumulator
        : currentCounter
  ); // returns the object with the highest counter and its value

  // 3 => ThreeOfAKind = sum of all dices
  if (
    (highestCounterObject.counter === 3 && numberOfRepetition === 3) ||
    (highestCounterObject.counter === 4 && numberOfRepetition === 3)
  ) {
    finalScore = getSumArrayNumbers(array);
  }

  // 4 => FourOfAKind = sum of all dices
  if (highestCounterObject.counter === 4 && numberOfRepetition === 4) {
    finalScore = getSumArrayNumbers(array);
  }

  // 6 => value < 6 => Full House: 25 points
  // 6 => value === 6 => Yahtzee: 50 points
  if (highestCounterObject.counter === 5 && numberOfRepetition === 5) {
    // Full House
    if (combinationName === "FullHouse") {
      finalScore = 25;
    } 

    // Yahtzee
    if (highestCounterObject.value === 6 && combinationName === "Yahtzee") {
      finalScore = 50;
    }
  }

  return finalScore;
};

export const getHighestStraight = (
  array: Array<number>,
  straightLengthFromScoreCard: number
) => {
  let straightLength: number = 1;
  let highestStraightLength: number = 0;
  let finalScore: number = 0;

  const findHighestStraight = array.reduce((accumulator, currentValue) => {
    const difference = currentValue - accumulator;
    if (difference === 1) {
      straightLength += 1;
    } else if (difference !== 0) {
      straightLength = 1;
    }

    if (highestStraightLength < straightLength)
      highestStraightLength = straightLength;

    return currentValue;
  });

  if (straightLengthFromScoreCard === 4 && highestStraightLength === 4 || straightLengthFromScoreCard === 4 && highestStraightLength === 5)
    finalScore = 30;

  if (straightLengthFromScoreCard === 5 && highestStraightLength === 5)
    finalScore = 40;

  return finalScore;
};

export const convertRecordPlayerFinalScoreToArray = (
  playerFinalScore: Record<CombinationNames, CombinationInfos>
) => {
  const playerFinalScoreRecordToArray = Object.entries(playerFinalScore).map(
    ([key, value]) => ({
      id: value.id,
      isAssigned: value.isAssigned,
      name: value.name,
      part: value.part,
      score: value.score,
    })
  );
  return playerFinalScoreRecordToArray;
};

export const getSubtotalOrTotalScore = (
  playerFinalScore: Record<CombinationNames, CombinationInfos>,
  numberPart: string,
  roundNumber: number,
  maxNumberOfTurns: number
) => {
  let sumTotal: number = 0;
  let subtotalPartOne = playerFinalScore.SubtotalPartOne;
  let subtotalPartTwo = playerFinalScore.SubtotalPartTwo;
  let bonusScore = playerFinalScore.Bonus;
  let totalScore = playerFinalScore.Total;

  const playerFinalScoreRecordToArray =
    convertRecordPlayerFinalScoreToArray(playerFinalScore);

  if (numberPart === 'one' || numberPart === 'two') {
    const partToCalculate = playerFinalScoreRecordToArray.filter(
      (score) => score.part === numberPart
    );

    const allScoresArrayOfCurrentPart = partToCalculate.map(
      (score) => score.score
    );

    sumTotal = getSumArrayNumbers(allScoresArrayOfCurrentPart);
  }

  if (numberPart === 'one') {
    subtotalPartOne.score = sumTotal;
    subtotalPartOne.isAssigned = true;
  }

  if (numberPart === 'two') {
    subtotalPartTwo.score = sumTotal;
    subtotalPartTwo.isAssigned = true;
  }

  if (numberPart === 'one' && sumTotal > 63) {
    bonusScore.score = 35;
    bonusScore.isAssigned = true;
  }

  if (numberPart === 'total') {
    const bonusPlusSubtotalScores =
      subtotalPartOne.score + subtotalPartTwo.score + bonusScore.score;

    sumTotal = bonusPlusSubtotalScores;

    totalScore.score = bonusPlusSubtotalScores;
    totalScore.isAssigned = true;
  }

  return sumTotal;
};

export const getTotalScore = (
  playerFinalScore: Record<CombinationNames, CombinationInfos>,
  roundNumber: number,
  maxNumberOfTurns: number
) => {
  let arrayAllScores: Array<number> = [];

  const playerFinalScoreRecordToArray =
    convertRecordPlayerFinalScoreToArray(playerFinalScore);

  playerFinalScoreRecordToArray.map((combinationData) =>
    arrayAllScores.push(combinationData.score)
  );

  const sumTotal = getSumArrayNumbers(arrayAllScores);

  return sumTotal;
};

export const determineWinner = (
  gameScoringOfAllPlayers: Array<GameScoring>
) => {
  let newArrayWithTotalScoreOfAllPlayers: Array<DefaultTotalScoreOfAllPlayers> =
    [];

  for (let index = 0; index < gameScoringOfAllPlayers.length; index++) {
    newArrayWithTotalScoreOfAllPlayers.push({
      playerId: gameScoringOfAllPlayers[index].playerId,
      totalScoring: gameScoringOfAllPlayers[index].finalScoring.Total.score,
    });
  }

  const findHighestScoresWithPlayerId = newArrayWithTotalScoreOfAllPlayers.reduce<Array<DefaultTotalScoreOfAllPlayers>>(
    (maxScore, currentValue) => {
      let accumulatorValue = maxScore[0]?.totalScoring ?? 0;
      if (accumulatorValue > currentValue.totalScoring) {
        return maxScore;
      } else if (accumulatorValue === currentValue.totalScoring) {
        return maxScore.concat(currentValue);
      } else {
        return [currentValue];
      }
    }, []);

  return findHighestScoresWithPlayerId;
};

export const createDefaultDicesScoring = (): Array<DefaultScoring> => {
  return [
    { score: 1, isSelected: false },
    { score: 2, isSelected: false },
    { score: 3, isSelected: false },
    { score: 4, isSelected: false },
    { score: 5, isSelected: false },
  ]
};