import React, { useEffect, useRef, useState } from 'react';

import './animated-logo.scss';

interface Position {
  x: number;
  y: number;
}

export interface ImageData {
  src: string;
  alt: string;
  title: string;
  className: string;
}
interface AnimatedLogoProps {
  imagesData: Array<ImageData>;
}

const AnimatedLogo: React.FC<AnimatedLogoProps> = (
  props: AnimatedLogoProps
) => {
  const { imagesData } = props;
  const logoRef = useRef<HTMLDivElement>(null);

  const [activeZoom, setActiveZoom] = useState<boolean>(false);
  const [mousePos, setMousePos] = useState<Position>({
    x: 10,
    y: 10,
  });
  const [scroll, setScroll] = useState<number>(0);
  const [images, setImages] = useState<Array<HTMLImageElement>>([]);

  useEffect(() => {
    const logo = logoRef.current;
    if (!logo) return;
    setImages(Array.from(logo.querySelectorAll<HTMLImageElement>('img')));
  }, [logoRef]);

  useEffect(() => {
    const logo = logoRef.current;
    if (!logo) return;

    const onMouseMove = (e: MouseEvent) => {
      setMousePos({
        x: e.clientX,
        y: e.clientY,
      });
    };
    const onScroll = (e: Event) => {
      const doc = document.documentElement;
      const left = doc.scrollLeft - (doc.clientLeft || 0);
      const top = doc.scrollTop - (doc.clientTop || 0);
      setScroll(top);
    };
    const onMouseLeave = () => {
      if (!activeZoom) resetLogo();
    };
    const onMouseDown = (e: MouseEvent) => {
      setActiveZoom(true);
      shiftLogo();
    };
    const onMouseUp = () => resetLogo();

    document.body.addEventListener('mouseleave', onMouseLeave);
    //window.addEventListener('mouseup', onMouseUp);
    //window.addEventListener('mousedown', onMouseDown);
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('scroll', onScroll);

    resetLogo();

    return () => {
      document.body.removeEventListener('mouseleave', onMouseLeave);
      //window.removeEventListener('mouseup', onMouseUp);
      //window.removeEventListener('mousedown', onMouseDown);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('scroll', onScroll);
    };
  }, [images]);

  useEffect(() => {
    shiftLogo();
  }, [mousePos, scroll]);

  const shift = (
    image: HTMLImageElement,
    index: number,
    rangeX: number,
    rangeY: number
  ) => {
    const active = activeZoom;

    const translationIntensity = active ? 24 : 4;

    const maxTranslation = translationIntensity * (index + 1);
    const currentTranslation = `${maxTranslation * rangeX}% ${
      maxTranslation * rangeY
    }%`;

    const scale = active ? 1 + index * 0.4 : 1;

    image.animate(
      {
        translate: currentTranslation,
        scale,
      },
      { duration: 750, fill: 'forwards', easing: 'ease' }
    );
  };

  const shiftAll = (
    images: HTMLImageElement[],
    rangeX: number,
    rangeY: number
  ) => {
    images.forEach((image, index) => shift(image, index, rangeX, rangeY));
  };

  const shiftLogo = () => {
    if (!logoRef.current) return;

    const rect = logoRef.current.getBoundingClientRect();
    const radius = 400;

    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;

    const rangeX = (mousePos.x - centerX) / radius;
    const rangeY = (mousePos.y - centerY) / radius;

    shiftAll(images, rangeX, rangeY);
  };

  const resetLogo = () => {
    setActiveZoom(false);
    shiftAll(images, 0.4, -0.7);
  };

  return (
    <div id="logos-container" ref={logoRef}>
      {imagesData.map((e, index) => {
        return (
          <img
            key={`animated-image-${index}`}
            className={e.className}
            src={e.src}
            alt={e.alt}
            title={e.title}
          />
        );
      })}
    </div>
  );
};

export default AnimatedLogo;
