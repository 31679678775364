// import yahtzeeLogo from '../assets/img/';

import { getTranslation } from '../helpers/common-helpers';
import {
  DefaultScoring,
  GameConfig,
  GameScoring,
  GameState,
  InputStringData,
} from '../interfaces/games-interfaces';
import './yahtzee-home-template.scss';
import YahtzeeConfigOrganism from '../components/organisms/games/yahtzee-config-organism';
import YahtzeeGameOrganism from '../components/organisms/games/yahtzee-game-organism';
import Footer from '../components/molecules/footer';
import { footerElementsData } from '../constants/showcase-website.constants';

export interface YahtzeeHomeTemplateProps {
  gameState: GameState;
  setGameState: (value: GameState) => void;
  gameConfig: GameConfig;
  setGameConfig: (value: GameConfig) => void;
}

export interface SelectedNumbersOfPlayersProps {
  selectedNumbersOfPlayers: number;
  setSelectedNumbersOfPlayers: (value: number) => void;
}

export interface GameScoringProps {
  gameScoring: Array<GameScoring>;
  setGameScoring: (value: Array<GameScoring>) => void;
}

export interface CurrentDicesScoringProps {
  currentDicesScoring: Array<DefaultScoring>;
  setCurrentDicesScoring: (value: Array<DefaultScoring>) => void;
}
export interface SelectedDicesProps {
  selectedDicesToReroll: Array<DefaultScoring>;
  setSelectedDicesToReroll: (value: Array<DefaultScoring>) => void;
}

export interface CurrentPlayerIdProps {
  selectedPlayerId: number;
  setSelectedPlayerId: (value: number) => void;
}

export interface RollNumberProps {
  rollNumber: number;
  setRollNumber: (value: number) => void;
}

export interface RoundNumberProps {
  roundNumber: number;
  setRoundNumber: (value: number) => void;
}

export interface ModalProps {
  isInstructionsModalOpen: boolean;
  setIsInstructionsModalOpen: (value: boolean) => void;
}

export interface ModalScoreCardProps {
  isScoreCardModalOpen: boolean;
  setIsScoreCardModalOpen: (value: boolean) => void;
}

export interface ModalGameEndProps {
  isGameEndContentModalOpen: boolean;
  setIsGameEndContentModalOpen: (value: boolean) => void;
}

export interface ModalGameInstructionsProps {
  isGameInstructionsModalOpen: boolean;
  setIsGameInstructionsModalOpen: (value: boolean) => void;
}

const YahtzeeHomeTemplate = (
  props: YahtzeeHomeTemplateProps &
    SelectedNumbersOfPlayersProps &
    GameScoringProps &
    CurrentDicesScoringProps &
    SelectedDicesProps &
    CurrentPlayerIdProps &
    RollNumberProps &
    RoundNumberProps &
    ModalProps &
    ModalScoreCardProps &
    ModalGameEndProps &
    ModalGameInstructionsProps
) => {
  const {
    gameState,
    setGameState,
    gameConfig,
    setGameConfig,
    selectedNumbersOfPlayers,
    setSelectedNumbersOfPlayers,
    gameScoring,
    setGameScoring,
    currentDicesScoring,
    setCurrentDicesScoring,
    selectedDicesToReroll,
    setSelectedDicesToReroll,
    selectedPlayerId,
    setSelectedPlayerId,
    rollNumber,
    setRollNumber,
    roundNumber,
    setRoundNumber,
    isInstructionsModalOpen,
    setIsInstructionsModalOpen,
    isScoreCardModalOpen,
    setIsScoreCardModalOpen,
    isGameEndContentModalOpen,
    setIsGameEndContentModalOpen,
    isGameInstructionsModalOpen,
    setIsGameInstructionsModalOpen,
  } = props;

  return (
    <div id="yahtzee-body">
      <header>
        <h1>{getTranslation('translation', 'main-title.yahtzee')}</h1>
        {/* <img src="../../public/y-logo.png" alt="Yahtzee Logo" /> */}
      </header>

      <main id="yahtzee-game-container">
        {gameState === 'inactive' && (
          <YahtzeeConfigOrganism
            gameState={gameState}
            setGameState={setGameState}
            gameConfig={gameConfig}
            setGameConfig={setGameConfig}
            gameScoring={gameScoring}
            setGameScoring={setGameScoring}
            selectedNumbersOfPlayers={selectedNumbersOfPlayers}
            setSelectedNumbersOfPlayers={setSelectedNumbersOfPlayers}
            setRoundNumber={setRoundNumber}
            setSelectedPlayerId={setSelectedPlayerId}
            setRollNumber={setRollNumber}
            setCurrentDicesScoring={setCurrentDicesScoring}
          />
        )}

        {(gameState === 'in game' || gameState === 'ended') && (
          <YahtzeeGameOrganism
            gameState={gameState}
            setGameState={setGameState}
            gameConfig={gameConfig}
            setGameConfig={setGameConfig}
            gameScoring={gameScoring}
            setGameScoring={setGameScoring}
            setSelectedNumbersOfPlayers={setSelectedNumbersOfPlayers}
            currentDicesScoring={currentDicesScoring}
            setCurrentDicesScoring={setCurrentDicesScoring}
            selectedDicesToReroll={selectedDicesToReroll}
            setSelectedDicesToReroll={setSelectedDicesToReroll}
            selectedPlayerId={selectedPlayerId}
            setSelectedPlayerId={setSelectedPlayerId}
            rollNumber={rollNumber}
            setRollNumber={setRollNumber}
            roundNumber={roundNumber}
            setRoundNumber={setRoundNumber}
            isInstructionsModalOpen={isInstructionsModalOpen}
            setIsInstructionsModalOpen={setIsInstructionsModalOpen}
            isScoreCardModalOpen={isScoreCardModalOpen}
            setIsScoreCardModalOpen={setIsScoreCardModalOpen}
            isGameEndContentModalOpen={isGameEndContentModalOpen}
            setIsGameEndContentModalOpen={setIsGameEndContentModalOpen}
            isGameInstructionsModalOpen={isGameInstructionsModalOpen}
            setIsGameInstructionsModalOpen={setIsGameInstructionsModalOpen}
          />
        )}
      </main>

      <footer>
        <a href="/">• ©mintfarben. Studio | 2024 •</a>
      </footer>
    </div>
  );
};

export default YahtzeeHomeTemplate;
