import { Link } from 'react-router-dom';
import { navbarElementsData } from '../../constants/showcase-website.constants';
import { DefaultNavbarElements } from '../../interfaces/showcase-website-interfaces';
import './navbar.scss';

interface NavbarProps {
  logoContent: string | JSX.Element;
  navItemIdClicked: Array<DefaultNavbarElements>;
  setNavItemIdClicked: (value: Array<DefaultNavbarElements>) => void;
}

const Navbar = (props: NavbarProps) => {
  const { logoContent, navItemIdClicked, setNavItemIdClicked } = props;

  const handleNavItemClicked = (navId: number) => {
    const copyNavItems = [...navItemIdClicked];

    copyNavItems.forEach((navItem, index) => (navItem.isClicked = false));
    copyNavItems[navId].isClicked = true;

    setNavItemIdClicked(copyNavItems);
  };

  return (
    <div id="nav-container">
      <nav id="homepage-nav">
        <div>{logoContent}</div>

        <div id="menuToggle">
          <input type="checkbox" />

          <span></span>
          <span></span>
          <span></span>

          <ul id="menu">
            {navbarElementsData.map((element) => {
              if (element.isActive) {
                return (
                  <li
                    key={element.id}
                    onClick={() => handleNavItemClicked(element.id)}
                  >
                    <a
                      href={element.anchor}
                      className={`${element.isClicked ? 'selected' : ''}`}
                    >
                      {element.content}
                    </a>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
