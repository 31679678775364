import './buttons-rules-list.scss';
import { GameConfig, GameState } from '../../../interfaces/games-interfaces';
import Button from '../../atoms/button/button';
import { arrayLeftIcon } from '../../../constants/icons-constants';
import { SetSelectedNumbersOfPlayersProps } from '../../organisms/games/yahtzee-game-organism';

interface ButtonsRulesListProps {
  setGameState: (value: GameState) => void;
  setGameConfig: (value: GameConfig) => void;
  resetGameConfig: () => void;
}

const ButtonsRulesList = (
  props: ButtonsRulesListProps & SetSelectedNumbersOfPlayersProps
) => {
  const {
    setGameState,
    setGameConfig,
    setSelectedNumbersOfPlayers,
    resetGameConfig,
  } = props;

  return (
    <div id="bottom-buttons-container">
      <Button
        className="back-button"
        content={<i className={arrayLeftIcon} />}
        onClick={() => resetGameConfig()}
        key={undefined}
        disabled={false}
      />

      <a
        href="https://youtu.be/AHDgpuEzopc?si=1GIO-suuJEV3Cmcv"
        target="_blank"
        className="question-mark-icon"
        title="Don't know how to damn play?"
      >
        <i className="fa-solid fa-question" />
      </a>
    </div>
  );
};

export default ButtonsRulesList;
