import { DotPosition } from '../components/atoms/dice/dice';
import {
  DefaultScoring,
  GameConfig,
  GameScoring,
  InputStringData,
  combinationsArray,
} from '../interfaces/games-interfaces';

export const numberOfThrowns: number = 3;
export const numberOfRounds: number = 13;
export const numberOfDices: number = 5;

export const ifOfFirstPlayer: number = 0;
export const firstRound: number = 1;

export const defaultInputStringData: InputStringData = {
  id: '',
  type: 'text',
  name: 'input-string',
  placeholder: 'write text here',
  value: '',
  onChange: function (e: any): void {
    throw new Error('Function not implemented');
  },
  required: false,
  minLength: 0,
  maxLength: 0
};

export const defaultGameConfig: GameConfig = {
  numberOfPlayers: 0,
  players: [],
  minPlayer: 2,
  maxPlayer: 4,
  maxNumberOfDices: numberOfDices,
  maxNumberOfThrowns: numberOfThrowns,
  maxNumberOfTurns: numberOfRounds,
};

export const defaultGameScoringData: GameScoring = {
  playerId: 0,
  thrownNumber: 0,
  turnNumber: 0,
  finalScoring: combinationsArray,
  isCurrentlyPlaying: false,
  cardIsOpened: false
};

export const defaultScoringData: Array<DefaultScoring> = [
  { score: 1, isSelected: false },
  { score: 2, isSelected: false },
  { score: 3, isSelected: false },
  { score: 4, isSelected: false },
  { score: 5, isSelected: false },
];

export const dotPositionMatrix: Record<number, Array<DotPosition>> = {
  1: [
    { top: 50,
      left: 50,
    },
  ],
  2: [
    {
      top: 20,
      left: 20,
    },
    {
      top: 80,
      left: 80,
    },
  ],
  3: [
    {
      top: 20,
      left: 20,
    },
    {
      top: 50,
      left: 50,
    },
    {
      top: 80,
      left: 80,
    },
  ],
  4: [
    {
      top: 20,
      left: 20,
    },
    {
      top: 20,
      left: 80,
    },
    {
      top: 80,
      left: 20,
    },
    {
      top: 80,
      left: 80,
    },
  ],
  5: [
    {
      top: 20,
      left: 20,
    },
    {
      top: 20,
      left: 80,
    },
    {
      top: 50,
      left: 50,
    },
    {
      top: 80,
      left: 20,
    },
    {
      top: 80,
      left: 80,
    },
  ],
  6: [
    {
      top: 20,
      left: 20,
    },
    {
      top: 20,
      left: 80,
    },
    {
      top: 50,
      left: 20,
    },
    {
      top: 50,
      left: 80,
    },
    {
      top: 80,
      left: 20,
    },
    {
      top: 80,
      left: 80,
    },
  ],
};
