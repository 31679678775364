interface IconProps {
  className: string;
}

const Icon = (props: IconProps) => {
  const { className } = props;

  return <i className={className} />;
};

export default Icon;
