import './input-number.scss';
import { InputData } from '../../../interfaces/list-order-interfaces';

interface InputNumberProps {
  inputsData: InputData;
}

const InputNumber = (props: InputNumberProps) => {
  const { inputsData } = props;

  return (
    <div>
      <input
        id={inputsData.id}
        className={inputsData.className}
        type={inputsData.type}
        name={inputsData.name}
        onChange={inputsData.onChange}
        value={inputsData.value}
        min={inputsData.min}
        max={inputsData.max}
      />
    </div>
  );
};

export default InputNumber;

// const handleChange = (e: any) => {
//   // console.log('e.target.value', e.target.value);
//   const value: number = Math.max(min, Math.min(max, Number(e.target.value)));
//   // console.log("value", value);
//   setNumbers(value);
// };
