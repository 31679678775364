import {
  DefaultCardElements,
  DefaultFooterElements,
  DefaultNavbarElements,
} from '../interfaces/showcase-website-interfaces';

export const navbarElementsData: Array<DefaultNavbarElements> = [
  {
    id: 0,
    content: 'mintfarben.',
    className: '',
    anchor: '/#mintfarben',
    isActive: true,
    isClicked: false,
  },
  {
    id: 1,
    content: 'SERVICES',
    className: '',
    anchor: '/#services',
    isActive: true,
    isClicked: false,
  },
  {
    id: 2,
    content: 'CLIENTS',
    className: '',
    anchor: '/#clients',
    isActive: true,
    isClicked: false,
  },
  {
    id: 3,
    content: 'PROJECTS',
    className: '',
    anchor: '',
    isActive: false,
    isClicked: false,
  },
  {
    id: 4,
    content: 'CONTACT',
    className: '',
    anchor: '/#contact',
    isActive: true,
    isClicked: false,
  },
];

export const footerElementsData: Array<DefaultFooterElements> = [
  {
    id: 0,
    category: 'basic',
    content: 'Mentions légales',
    className: 'terms-conditions',
    iconClassname: '',
    internalLink: '/terms-conditions',
    externalLink: '',
    isActive: true,
    isSelected: false,
  },
  {
    id: 1,
    category: 'basic',
    content: '• ©mintfarben. Studio | 2024 •',
    className: 'mintfarben-element-footer',
    iconClassname: '',
    internalLink: 'mailto:mintfarben.studio@gmail.com',
    externalLink: '',
    isActive: true,
    isSelected: false,
  },
  {
    id: 2,
    category: 'basic',
    content: 'Politique de confidentialité',
    className: 'privacy-policy',
    iconClassname: '',
    internalLink: '/privacy-policy',
    externalLink: '',
    isActive: true,
    isSelected: false,
  },
  {
    id: 3,
    category: 'social-networks',
    content: '',
    className: 'yahtzee-game',
    iconClassname: 'fa-solid fa-dice-six',
    internalLink: '/yahtzee-game',
    externalLink: '',
    isActive: false,
    isSelected: false,
  },
  {
    id: 4,
    category: 'social-networks',
    content: '',
    className: 'linkedin-icon',
    iconClassname: 'fa-brands fa-linkedin-in',
    internalLink: '',
    externalLink: '',
    isActive: false,
    isSelected: false,
  },
  {
    id: 5,
    category: 'social-networks',
    content: '',
    className: 'instagram-icon',
    iconClassname: 'fa-brands fa-instagram',
    internalLink: '',
    externalLink: 'https://www.instagram.com/mintfarben_studio',
    isActive: false,
    isSelected: false,
  },
  {
    id: 6,
    category: 'settings',
    content: '',
    className: 'dark-light-theme',
    iconClassname: 'fa-regular fa-lightbulb',
    internalLink: '',
    externalLink: '',
    isActive: false,
    isSelected: false,
  },
  {
    id: 7,
    category: 'settings',
    content: '',
    className: 'theme-langage-container',
    iconClassname: '',
    internalLink: '',
    externalLink: '',
    isActive: false,
    isSelected: false,
  },
];

export const servicesElementsData: Array<DefaultCardElements> = [
  {
    id: 0,
    mainDivClassName: 'card-style services-card',
    iconClassName: '',
    h3Title: 'Site Internet optimisé (SEO)',
    ulClassName: 'services-list seo-website-list',
    liContentList: [
      <>
        Conception et refonte de sites web responsive, conçus sur mesure ou sur
        WordPress.
      </>,
      <>
        Sites vitrines ou e-commerce qui reflètent parfaitement votre marque,
        avec une expérience utilisateur optimisée.
      </>,
      <>
        Optimisation du contenu pour améliorer le référencement naturel (SEO) et
        attirer plus de trafic organique.
      </>,
    ],
  },
  {
    id: 1,
    mainDivClassName: 'card-style services-card',
    iconClassName: '',
    h3Title: 'Copywriting',
    ulClassName: 'services-list copywriting-buying-list',
    liContentList: [
      <>
        Des textes percutants et engageants qui captivent l'attention de votre
        audience cible.
      </>,
      <>
        Un message clair et convaincant qui augmente les taux de conversion et
        génère des leads qualifiés.
      </>,
      <>
        Un contenu adapté à votre audience cible, renforçant votre marque et
        établissant votre autorité dans votre domaine.
      </>,
    ],
  },
];
