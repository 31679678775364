import { MaxNumberOfThrownsProps } from '../../molecules/games/dices-list';
import './dice.scss';

interface DiceProps {
  dotsPositionArray: Array<DotPosition>;
  className: string;
}

export interface DotPosition {
  top: number;
  left: number;
}

export interface RollNumberProps {
  rollNumber: number;
}

const Dice = (props: DiceProps & RollNumberProps & MaxNumberOfThrownsProps) => {
  const { dotsPositionArray, className, rollNumber, maxNumberOfThrowns } =
    props;

  const getDiceFaces = () => {
    let diceComponent: Array<JSX.Element> = [];

    for (let y = 0; y < dotsPositionArray.length; y++) {
      diceComponent.push(
        <div
          key={`dot-pos-${y}`}
          className={'dice-dot'}
          style={{
            top: `${dotsPositionArray[y].top}%`,
            left: `${dotsPositionArray[y].left}%`,
            transform: `translateX(-${dotsPositionArray[y].left}%) translateY(-${dotsPositionArray[y].top}%)`,
          }}
        />
      );
    }
    return diceComponent;
  };

  return (
    <div>
      <div className={`dice-container ${className}`}>
        <div
          className={
            rollNumber > 0 && rollNumber < maxNumberOfThrowns
              ? 'dice dice-selection-allowed'
              : 'dice dice-selection-not-allowed'
          }
        >
          {getDiceFaces()}
        </div>
      </div>
    </div>
  );
};

export default Dice;
