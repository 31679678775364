import './card.scss';
import { DefaultCardElements } from '../../interfaces/showcase-website-interfaces';
import Icon from '../atoms/icon/icon';

interface CardProps {
  content: DefaultCardElements;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  isHovered: boolean;
}

const Card = (props: CardProps) => {
  const { content, onMouseEnter, onMouseLeave, isHovered } = props;
  return (
    <div
      className={`${content.mainDivClassName} ${isHovered ? 'blurred' : ''}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <h3>
        <Icon className={content.iconClassName} />
        {content.h3Title}
      </h3>
      <ul className={content.ulClassName}>
        {content.liContentList.map((element) => {
          return <li>{element}</li>;
        })}
      </ul>
    </div>
  );
};

export default Card;
