import './yahtzee-config-organism.scss';
import {
  createArrayFromRange,
  getTranslation,
} from '../../../helpers/common-helpers';
import {
  GameScoringProps,
  SelectedNumbersOfPlayersProps,
  YahtzeeHomeTemplateProps,
} from '../../../templates/yahtzee-home-template';
import Button from '../../atoms/button/button';
import {
  CombinationInfos,
  CombinationNames,
  DefaultScoring,
  GameConfig,
} from '../../../interfaces/games-interfaces';
import { SetSelectedPlayerIdProps } from '../../molecules/games/players-list';
import {
  dotPositionMatrix,
  firstRound,
  ifOfFirstPlayer,
} from '../../../constants/games-constants';
import Dice from '../../atoms/dice/dice';
import { createDefaultDicesScoring } from '../../../helpers/games/games-helpers';

interface SetRoundNumberProps {
  setRoundNumber: (value: number) => void;
}

export interface SetRollNumberProps {
  setRollNumber: (value: number) => void;
}

interface SetCurrentDicesScoringProps {
  setCurrentDicesScoring: (value: Array<DefaultScoring>) => void;
}

const YahtzeeConfigOrganism = (
  props: YahtzeeHomeTemplateProps &
    SelectedNumbersOfPlayersProps &
    GameScoringProps &
    SetRoundNumberProps &
    SetSelectedPlayerIdProps &
    SetRollNumberProps &
    SetCurrentDicesScoringProps
) => {
  const {
    setGameState,
    gameConfig,
    setGameConfig,
    setGameScoring,
    selectedNumbersOfPlayers,
    setSelectedNumbersOfPlayers,
    setRoundNumber,
    setSelectedPlayerId,
    setRollNumber,
    setCurrentDicesScoring,
  } = props;

  const createDefaultCombinationsArray = (): Record<
    CombinationNames,
    CombinationInfos
  > => {
    return {
      Ones: {
        id: 1,
        name: 'Ones',
        score: 0,
        isAssigned: false,
        part: 'one',
      },
      Twos: {
        id: 2,
        name: 'Twos',
        score: 0,
        isAssigned: false,
        part: 'one',
      },
      Threes: {
        id: 3,
        name: 'Threes',
        score: 0,
        isAssigned: false,
        part: 'one',
      },
      Fours: {
        id: 4,
        name: 'Fours',
        score: 0,
        isAssigned: false,
        part: 'one',
      },
      Fives: {
        id: 5,
        name: 'Fives',
        score: 0,
        isAssigned: false,
        part: 'one',
      },
      Sixes: {
        id: 6,
        name: 'Sixes',
        score: 0,
        isAssigned: false,
        part: 'one',
      },
      ThreeOfAKind: {
        id: 7,
        name: 'ThreeOfAKind',
        score: 0,
        isAssigned: false,
        part: 'two',
      },
      FourOfAKind: {
        id: 8,
        name: 'FourOfAKind',
        score: 0,
        isAssigned: false,
        part: 'two',
      },
      SmallStraight: {
        id: 9,
        name: 'SmallStraight',
        score: 0,
        isAssigned: false,
        part: 'two',
      },
      LargeStraight: {
        id: 10,
        name: 'LargeStraight',
        score: 0,
        isAssigned: false,
        part: 'two',
      },
      FullHouse: {
        id: 11,
        name: 'FullHouse',
        score: 0,
        isAssigned: false,
        part: 'two',
      },
      Chance: {
        id: 12,
        name: 'Chance',
        score: 0,
        isAssigned: false,
        part: 'two',
      },
      Yahtzee: {
        id: 13,
        name: 'Yahtzee',
        score: 0,
        isAssigned: false,
        part: 'two',
      },
      Bonus: {
        id: 14,
        name: 'Bonus',
        score: 0,
        isAssigned: false,
        part: '0',
      },
      SubtotalPartOne: {
        id: 15,
        name: 'SubtotalPartOne',
        score: 0,
        isAssigned: false,
        part: '0',
      },
      SubtotalPartTwo: {
        id: 16,
        name: 'SubtotalPartTwo',
        score: 0,
        isAssigned: false,
        part: '0',
      },
      Total: {
        id: 17,
        name: 'Total',
        score: 0,
        isAssigned: false,
        part: 'total',
      },
    };
  };

  const updateGameConfig = (
    gameConfig: GameConfig,
    numberOfPlayers: number
  ) => {
    let updatedGamePlayers = [];
    let defaultScoreCardsPerPlayer = [];
    let counter = 1;

    for (let y = 0; y < numberOfPlayers; y++) {
      updatedGamePlayers.push({
        id: y,
        name: `Player ${counter + y}`,
        icon: `Player ${counter + y}`,
      });
      defaultScoreCardsPerPlayer.push({
        playerId: y,
        thrownNumber: y,
        turnNumber: y,
        finalScoring: createDefaultCombinationsArray(),
        isCurrentlyPlaying: false,
        cardIsOpened: false,
      });
    }

    defaultScoreCardsPerPlayer[0].isCurrentlyPlaying = true; // defining player's turn: 1st player

    gameConfig.players = updatedGamePlayers; // defining players' id, name & icon

    setGameConfig({
      ...gameConfig,
      numberOfPlayers: numberOfPlayers,
      players: updatedGamePlayers,
    });

    setGameScoring(defaultScoreCardsPerPlayer); // defining player's thrown, turn, scoring & hasPlayed boolean
    setSelectedNumbersOfPlayers(numberOfPlayers);
    setSelectedPlayerId(0);
  };

  const startGame = () => {
    setGameState('in game');
    setRoundNumber(firstRound); // defining the 1st round
    setRollNumber(ifOfFirstPlayer);
    setCurrentDicesScoring(createDefaultDicesScoring());
  };

  return (
    <div id="yahtzee-config-organism-container">
      <div className="mini-dices-container">
        {selectedNumbersOfPlayers > 0 &&
          createArrayFromRange(1, selectedNumbersOfPlayers).map(
            (numberOfPlayers, index) => (
              <Dice
                key={index}
                dotsPositionArray={dotPositionMatrix[numberOfPlayers]}
                className="small-dices-to-reroll mini-dices-nb-players"
                rollNumber={0}
                maxNumberOfThrowns={0}
              />
            )
          )}
      </div>

      <h2>
        {getTranslation('translation', 'yahtzee.titles.how-many-players')}
      </h2>

      <div id="nb-players-buttons-container">
        {createArrayFromRange(gameConfig.minPlayer, gameConfig.maxPlayer).map(
          (numberOfPlayers, index) => (
            <Button
              key={index}
              className={
                selectedNumbersOfPlayers === numberOfPlayers
                  ? 'plain-button nb-players-selected-buttons'
                  : 'plain-button nb-players-not-selected-buttons'
              }
              content={
                numberOfPlayers +
                ' ' +
                getTranslation('translation', 'yahtzee.content.players')
              }
              onClick={() => updateGameConfig(gameConfig, numberOfPlayers)}
              disabled={false}
            />
          )
        )}
      </div>

      <h2>{getTranslation('translation', 'yahtzee.titles.ready')}?</h2>
      {gameConfig.players.length > 1 ? (
        <Button
          className={'gaming-button start-button'}
          content={getTranslation('translation', 'yahtzee.buttons.start')}
          onClick={() => startGame()}
        />
      ) : (
        <Button
          className={'gaming-button start-button-disabled'}
          content={getTranslation('translation', 'yahtzee.buttons.start')}
          onClick={() => ''}
          disabled
        />
      )}
    </div>
  );
};

export default YahtzeeConfigOrganism;
