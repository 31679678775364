export type GameState = 'inactive' | 'initialized' | 'in game' | 'ended' | 'error';
export type GameName = 'Yahtzee' | 'Uno';
export type CombinationNames = 'Ones' | 'Twos' | 'Threes' | 'Fours' | 'Fives' | 'Sixes' | 'ThreeOfAKind' | 'FourOfAKind' | 'SmallStraight' | 'LargeStraight' | 'FullHouse' | 'Chance' | 'Yahtzee' | 'Bonus' | 'SubtotalPartOne' | 'SubtotalPartTwo' | 'Total'; 
export type CombinationInfos = {
  id: number,
  name: CombinationNames,
  score: number,
  isAssigned: boolean,
  part: string
}

// IGameManager => I indique qu'on n'instancie pas l'interface mais qu'elle va être héritée
export interface IGameInstance {
  initGame: () => void;
  renderGame: () => JSX.Element;
  state: GameState;
  name: GameName;
  config: GameConfig;
}

export interface GameConfig {
  numberOfPlayers: number;
  players: Array<GamePlayer>;
  minPlayer: number;
  maxPlayer: number;
  maxNumberOfDices: number;
  maxNumberOfThrowns: number;
  maxNumberOfTurns: number;
}

export interface GamePlayer {
  id: number;
  name: string;
  icon: string;
}

export interface InputStringData {
  id: string;
  className?: string;
  type: string;
  placeholder: string;
  name: string;
  value: string;
  onChange: (e: any) => void;
  required: boolean;
  minLength: number;
  maxLength: number;
}

export interface GameScoring {
  playerId: number;
  thrownNumber: number;
  turnNumber: number;
  finalScoring: Record<CombinationNames, CombinationInfos> ;
  isCurrentlyPlaying: boolean;
  cardIsOpened: boolean;
}

export interface DefaultScoring {
  score: number;
  isSelected: boolean;
}

export interface DefaultRepeatedNumber {
  value: number;
  counter: number;
}

export interface DefaultTotalScoreOfAllPlayers {
  playerId: number;
  totalScoring: number;
}


export const combinationsArray:Record<CombinationNames, CombinationInfos> = {
  Ones: {
    id: 1,
    name: 'Ones',
    score: 0,
    isAssigned: false,
    part: 'one',
  },
  Twos: {
    id: 2,
    name: 'Twos',
    score: 0,
    isAssigned: false,
    part: 'one',
  },
  Threes: {
    id: 3,
    name: 'Threes',
    score: 0,
    isAssigned: false,
    part: 'one',
  },
  Fours: {
    id: 4,
    name: 'Fours',
    score: 0,
    isAssigned: false,
    part: 'one',
  },
  Fives: {
    id: 5,
    name: 'Fives',
    score: 0,
    isAssigned: false,
    part: 'one',
  },
  Sixes: {
    id: 6,
    name: 'Sixes',
    score: 0,
    isAssigned: false,
    part: 'one',
  },
  ThreeOfAKind: {
    id: 7,
    name: 'ThreeOfAKind',
    score: 0,
    isAssigned: false,
    part: 'two',
  },
  FourOfAKind: {
    id: 8,
    name: 'FourOfAKind',
    score: 0,
    isAssigned: false,
    part: 'two',
  },
  SmallStraight: {
    id: 9,
    name: 'SmallStraight',
    score: 0,
    isAssigned: false,
    part: 'two',
  },
  LargeStraight: {
    id: 10,
    name: 'LargeStraight',
    score: 0,
    isAssigned: false,
    part: 'two',
  },
  FullHouse: {
    id: 11,
    name: 'FullHouse',
    score: 0,
    isAssigned: false,
    part: 'two',
  },
  Chance: {
    id: 12,
    name: 'Chance',
    score: 0,
    isAssigned: false,
    part: 'two',
  },
  Yahtzee: {
    id: 13,
    name: 'Yahtzee',
    score: 0,
    isAssigned: false,
    part: 'two',
  },
  Bonus: {
    id: 14,
    name: 'Bonus',
    score: 0,
    isAssigned: false,
    part: '0',
  },
  SubtotalPartOne: {
    id: 15,
    name: 'SubtotalPartOne',
    score: 0,
    isAssigned: false,
    part: '0',
  },
  SubtotalPartTwo: {
    id: 16,
    name: 'SubtotalPartTwo',
    score: 0,
    isAssigned: false,
    part: '0',
  },
  Total: {
    id: 17,
    name: 'Total',
    score: 0,
    isAssigned: false,
    part: 'total',
  },
};