import { useContext, useState } from 'react';
import {
  DefaultScoring,
  GameConfig,
  GameScoring,
  GameState,
} from '../interfaces/games-interfaces';
import YahtzeeHomeTemplate from '../templates/yahtzee-home-template';
import { GlobalContext } from '../contexts/global-context';
import {
  defaultGameScoringData,
  defaultScoringData,
} from '../constants/games-constants';
import { createDefaultGameConfig } from '../helpers/games/games-helpers';

const YahtzeePage = () => {
  const globalContext = useContext(GlobalContext);

  const [gameState, setGameState] = useState<GameState>('inactive');
  const [gameConfig, setGameConfig] = useState<GameConfig>(
    createDefaultGameConfig()
  );
  const [selectedNumbersOfPlayers, setSelectedNumbersOfPlayers] = useState(0);
  const [currentDicesScoring, setCurrentDicesScoring] =
    useState<Array<DefaultScoring>>(defaultScoringData);
  const [selectedDicesToReroll, setSelectedDicesToReroll] = useState<
    Array<DefaultScoring>
  >([]);
  const [gameScoring, setGameScoring] = useState<Array<GameScoring>>([
    defaultGameScoringData,
  ]); // playerId + thrownNumber + turnNumber + finalScoring + hasPlayed (boolean) + cardIsOpened (boolean)
  const [selectedPlayerId, setSelectedPlayerId] = useState(0);
  const [rollNumber, setRollNumber] = useState<number>(0); // nbre  de lancés - 3x max
  const [roundNumber, setRoundNumber] = useState(0); // nbre de tours - 13x max
  const [isInstructionsModalOpen, setIsInstructionsModalOpen] =
    useState<boolean>(false);
  const [isScoreCardModalOpen, setIsScoreCardModalOpen] =
    useState<boolean>(false);
  const [isGameEndContentModalOpen, setIsGameEndContentModalOpen] =
    useState<boolean>(false);
  const [isGameInstructionsModalOpen, setIsGameInstructionsModalOpen] =
    useState<boolean>(false);

  if (globalContext.isLoading) return <div>Loading...</div>;

  return (
    <YahtzeeHomeTemplate
      gameState={gameState}
      setGameState={setGameState}
      gameConfig={gameConfig}
      setGameConfig={setGameConfig}
      selectedNumbersOfPlayers={selectedNumbersOfPlayers}
      setSelectedNumbersOfPlayers={setSelectedNumbersOfPlayers}
      gameScoring={gameScoring}
      setGameScoring={setGameScoring}
      currentDicesScoring={currentDicesScoring}
      setCurrentDicesScoring={setCurrentDicesScoring}
      selectedDicesToReroll={selectedDicesToReroll}
      setSelectedDicesToReroll={setSelectedDicesToReroll}
      selectedPlayerId={selectedPlayerId}
      setSelectedPlayerId={setSelectedPlayerId}
      rollNumber={rollNumber}
      setRollNumber={setRollNumber}
      roundNumber={roundNumber}
      setRoundNumber={setRoundNumber}
      isInstructionsModalOpen={isInstructionsModalOpen}
      setIsInstructionsModalOpen={setIsInstructionsModalOpen}
      isScoreCardModalOpen={isScoreCardModalOpen}
      setIsScoreCardModalOpen={setIsScoreCardModalOpen}
      isGameEndContentModalOpen={isGameEndContentModalOpen}
      setIsGameEndContentModalOpen={setIsGameEndContentModalOpen}
      isGameInstructionsModalOpen={isGameInstructionsModalOpen}
      setIsGameInstructionsModalOpen={setIsGameInstructionsModalOpen}
    />
  );
};

export default YahtzeePage;

// Cette page = manager
// Contient la donnée
// En fonction de l'étape où j'en suis, ça m'affichera tel ou tel composant
