import React from 'react';
import { GlobalContextProvider } from './components/providers/global-context-provider';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Homepage from './pages/homepage';
import ListOrderPage from './pages/list-order-page';
import YahtzeePage from './pages/yahtzee-page';
import GamesPlatformPage from './pages/games-platform-page';
import FeaturesPage from './pages/features-page';
import LayoutPage from './pages/layout-page';
import TermsConditionsPage from './pages/terms-conditions-page';
import PrivacyPolicyPage from './pages/privacy-policy-page';

const App = () => {
  return (
    <BrowserRouter>
      <GlobalContextProvider>
        <Routes>
          <Route
            path="/"
            element={
              <LayoutPage>
                <Homepage />
              </LayoutPage>
            }
          />
          <Route
            path="/terms-conditions"
            element={
              <LayoutPage>
                <TermsConditionsPage />
              </LayoutPage>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <LayoutPage>
                <PrivacyPolicyPage />
              </LayoutPage>
            }
          />
          <Route path="/yahtzee-game" element={<YahtzeePage />} />
          <Route path="/random-numbers" element={<ListOrderPage />} />
          <Route
            path="*"
            element={
              <LayoutPage>
                {/* <NotFound404Page /> */}
                <Homepage />
              </LayoutPage>
            }
          />

          {/* <Route path="/games-platform" element={<GamesPlatformPage />} /> */}
          {/* <Route path="/features" element={<FeaturesPage />} /> */}
          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
        </Routes>
      </GlobalContextProvider>
    </BrowserRouter>
  );
};

export default App;
