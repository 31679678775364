import './list.scss';
import Button from '../atoms/button/button';
import InputNumber from '../atoms/input-number/input-number';
import { InputData } from '../../interfaces/list-order-interfaces';
import { trashIcon } from '../../constants/icons-constants';

interface ListProps {
  inputsData: InputData[];
  setInputs: (value: Array<number>) => void;
}

const List = (props: ListProps) => {
  const { inputsData, setInputs } = props;

  return (
    <div id="list-container">
      <ul>
        {inputsData.map((inputData, index) => {
          return (
            <li key={index}>
              <InputNumber inputsData={inputData} />
              <Button
                className="icon-trash"
                disabled={false}
                content={<i className={trashIcon} />}
                onClick={() => {
                  const copyInputsData = [...inputsData];
                  copyInputsData.splice(index, 1); // splice(start, deleteCount)

                  const otherInputs = copyInputsData.map(
                    (input) => input.value
                  );

                  setInputs(otherInputs);
                }}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default List;
