import HomepageOrganism from '../components/organisms/homepage-organism';

interface HomeTemplateProps {
  calendlyTag: string | JSX.Element;
  mailContactContent: string | JSX.Element;
  hoveredCardId: number | null;
  handleMouseEnter: (value: number) => void;
  handleMouseLeave: () => void;
  handleButtonMouseEnter: (value: number) => void;
  handleButtonMouseLeave: () => void;
}

const HomeTemplate = (props: HomeTemplateProps) => {
  const {
    calendlyTag,
    mailContactContent,
    hoveredCardId,
    handleMouseEnter,
    handleMouseLeave,
    handleButtonMouseEnter,
    handleButtonMouseLeave,
  } = props;

  return (
    <HomepageOrganism
      calendlyTag={calendlyTag}
      mailContactContent={mailContactContent}
      hoveredCardId={hoveredCardId}
      handleMouseEnter={handleMouseEnter}
      handleMouseLeave={handleMouseLeave}
      handleButtonMouseEnter={handleButtonMouseEnter}
      handleButtonMouseLeave={handleButtonMouseLeave}
    />
  );
};

export default HomeTemplate;
