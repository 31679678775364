import { useEffect, useState } from 'react';
import { GlobalContext } from '../../contexts/global-context';
import { initializeI18NextWithCallBack } from '../../i18n';

interface GlobalContextProviderProps {
  children: string | JSX.Element | JSX.Element[];
}

export const GlobalContextProvider = (props: GlobalContextProviderProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    initializeI18NextWithCallBack(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <GlobalContext.Provider value={{ isLoading, setIsLoading }}>
      {props.children}
    </GlobalContext.Provider>
  );
};
