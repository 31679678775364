import './list-organism.scss';
import Button, { ButtonProps } from '../atoms/button/button';
import List from '../molecules/list';
import { InputData } from '../../interfaces/list-order-interfaces';

interface ListOrganismProps {
  buttonsData: Array<ButtonProps>;
  inputsData: Array<InputData>;
  setInputs: (value: Array<number>) => void;
}

const ListOrganism = (props: ListOrganismProps) => {
  return (
    <div>
      <List inputsData={props.inputsData} setInputs={props.setInputs} />

      <div className="buttons-list-container">
        {props.buttonsData.map((element, i) => {
          return (
            <Button
              key={i}
              className={element.className}
              content={element.content}
              onClick={element.onClick}
              disabled={false}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ListOrganism;
