import './modal.scss';

interface ModalProps {
  className: string;
  content: string | JSX.Element;
}

const Modal = (props: ModalProps) => {
  const { className, content } = props;

  return <div className={`modal-container ${className}`}>{content}</div>;
};

export default Modal;
