const PrivacyPolicyOrganism = () => {
  return (
    <main id="terms-conditions" className="section-standard-padding-left-right">
      <h1>Politique de confidentialité</h1>
      <section>
        <h2>ARTICLE 1 : PRÉAMBULE</h2>
        <p>
          La présente politique de confidentialité a pour but d’informer les
          utilisateurs du site : <br></br>- Sur la manière dont sont collectées
          leurs données personnelles. Sont considérées comme des données
          personnelles, toute information permettant d’identifier un
          utilisateur. À ce titre, il peut s’agir : de ses noms et prénoms, de
          son âge, de son adresse postale ou email, de sa localisation ou encore
          de son adresse IP (liste non-exhaustive) ; <br></br>- Sur les droits
          dont ils disposent concernant ces données ; <br></br>- Sur la personne
          responsable du traitement des données à caractère personnel collectées
          et traitées ; <br></br>- Sur les destinataires de ces données
          personnelles ; <br></br>- Sur la politique du site en matière de
          cookies. <br></br>
          Cette politique complète les mentions légales par les utilisateurs à
          l’adresse suivante :{' '}
          <a href="https://mintfarben-studio.com/terms-conditions">
            https://mintfarben-studio.com/terms-conditions
          </a>
        </p>
      </section>

      <section>
        <h2>
          ARTICLE 2 : PRINCIPES RELATIFS À LA COLLECTE ET AU TRAITEMENT DES
          DONNÉES PERSONNELLES
        </h2>
        <p>
          Conformément à l’article 5 du Règlement européen 2016/679, les données
          à caractère personnel sont : <br></br>- Traitées de manière licite,
          loyale et transparente au regard de la personne concernée ; <br></br>-
          Collectées pour des finalités déterminées (cf. Article 3.1 des
          présentes), explicites et légitimes, et ne pas être traitées
          ultérieurement d'une manière incompatible avec ces finalités ;{' '}
          <br></br>- Adéquates, pertinentes et limitées à ce qui est nécessaire
          au regard des finalités pour lesquelles elles sont traitées ;{' '}
          <br></br>- Exactes et, si nécessaire, tenues à jour. Toutes les
          mesures raisonnables doivent être prises pour que les données à
          caractère personnel qui sont inexactes, eu égard aux finalités pour
          lesquelles elles sont traitées, soient effacées ou rectifiées sans
          tarder ; <br></br>- Conservées sous une forme permettant
          l'identification des personnes concernées pendant une durée n'excédant
          pas celle nécessaire au regard des finalités pour lesquelles elles
          sont traitées ; <br></br>- Traitées de façon à garantir une sécurité
          appropriée des données collectées, y compris la protection contre le
          traitement non autorisé ou illicite et contre la perte, la destruction
          ou les dégâts d'origine accidentelle, à l'aide de mesures techniques
          ou organisationnelles appropriées.
        </p>

        <p>
          Le traitement n'est licite que si, et dans la mesure où, au moins une
          des conditions suivantes est remplie : <br></br>- La personne
          concernée a consenti au traitement de ses données à caractère
          personnel pour une ou plusieurs finalités spécifiques ; <br></br>- Le
          traitement est nécessaire à l'exécution d'un contrat auquel la
          personne concernée est partie ou à l'exécution de mesures
          précontractuelles prises à la demande de celle-ci ; <br></br>- Le
          traitement est nécessaire au respect d'une obligation légale à
          laquelle le responsable du traitement est soumis ; <br></br>- Le
          traitement est nécessaire à la sauvegarde des intérêts vitaux de la
          personne concernée ou d'une autre personne physique ; <br></br>- Le
          traitement est nécessaire à l'exécution d'une mission d'intérêt public
          ou relevant de l'exercice de l'autorité publique dont est investi le
          responsable du traitement ; <br></br>- Le traitement est nécessaire
          aux fins des intérêts légitimes poursuivis par le responsable du
          traitement ou par un tiers, à moins que ne prévalent les intérêts ou
          les libertés et droits fondamentaux de la personne concernée qui
          exigent une protection des données à caractère personnel, notamment
          lorsque la personne concernée est un enfant.
        </p>
      </section>

      <section>
        <h2>
          ARTICLE 3 : DONNÉES À CARACTÈRE PERSONNEL COLLECTÉES ET TRAITÉES DANS
          LE CADRE DE LA NAVIGATION SUR LE SITE
        </h2>
        <h3>Article 3.1 : Données collectées</h3>
        <p>
          Les données personnelles collectées dans le cadre de notre activité
          sont les suivantes : données de navigation, pages consultées.
        </p>
        <p>
          La collecte et le traitement de ces données répond à la (aux)
          finalité(s) suivante(s) : personnalisation de l'expérience utilisateur
          sur le site, amélioration du fonctionnement du site.
        </p>

        <h3>Article 3.2 : Mode de collecte des données</h3>
        <p>
          Lorsque vous utilisez notre site, sont automatiquement collectées les
          données suivantes : données de navigation, pages consultées. <br></br>
          D’autres données personnelles sont collectées lorsque vous effectuez
          les opérations suivantes sur la plateforme : personnalisation de
          l'expérience utilisateur sur le site, amélioration du fonctionnement
          du site.
        </p>

        <p>
          Elles sont conservées par le responsable du traitement dans des
          conditions raisonnables de sécurité, pour la durée nécessaire à la
          réalisation des finalités pour lesquelles elles ont été collectées. La
          société est susceptible de conserver certaines données à caractère
          personnel au-delà des délais annoncés ci-dessus afin de remplir ses
          obligations légales ou réglementaires.
        </p>

        <h3>Article 3.3 : Hébergement des données</h3>
        <p>
          Le site https://mintfarben-studio.com est hébergé par HOSTINGER
          operations, UAB dont le siège social est situé à Švitrigailos str. 34,
          Vilnius 03230 Lithuania, joignable par le moyen suivant :{' '}
          <a href="mailto:domains@hostinger.com">domains@hostinger.com</a> -
          +37064503378.
        </p>
      </section>

      <section>
        <h2>
          ARTICLE 4 : RESPONSABLE DU TRAITEMENT DES DONNÉES ET DÉLÉGUÉ À LA
          PROTECTION DES DONNÉES
        </h2>
        <h3>Article 4.1 : Le responsable du traitement des données</h3>
        <p>
          Les données à caractère personnelles sont collectées par mintfarben.
          Studio EI, dont le numéro d’immatriculation est le SIRET
          98768836300017 et est domiciliée à Paris. Le responsable du traitement
          des données à caractère personnel peut être contacté de la manière
          suivante : <br></br>- Par courrier à l’adresse : Marilène Khieu, 9 rue
          d'Aubervilliers, 75018 Paris ; <br></br>- Par téléphone, au
          07.67.62.08.77 ; <br></br>- Par mail :{' '}
          <a href="mailto:mintfarben.studio@gmail.com">
            mintfarben.studio@gmail.com
          </a>
          .
        </p>
        <p>
          Si vous estimez, après nous avoir contactés, que vos droits
          “Informatique et Libertés”, ne sont pas respectés, vous pouvez
          adresser une information à la CNIL.
        </p>
      </section>

      <section>
        <h2>
          ARTICLE 5 : LES DROITS DE L’UTILISATEUR EN MATIÈRE DE COLLECTE ET DE
          TRAITEMENT DES DONNÉES
        </h2>
        <p>
          Tout utilisateur concerné par le traitement de ses données
          personnelles peut se prévaloir des droits suivants, en application du
          règlement européen 2016/679 et de la Loi Informatique et Liberté (Loi
          78-17 du 6 janvier 1978) : <br></br>- Droit d’accès, de rectification
          et droit à l’effacement des données (posés respectivement aux articles
          15, 16 et 17 du RGPD) ; <br></br>- Droit à la portabilité des données
          (article 20 du RGPD) ; <br></br>- Droit à la limitation (article 18 du
          RGPD) et à l’opposition du traitement des données (article 21 du RGPD)
          ; <br></br>- Droit de ne pas faire l’objet d’une décision fondée
          exclusivement sur un procédé automatisé ; <br></br>- Droit de
          déterminer le sort des données après la mort ;<br></br>- Droit de
          saisir l’autorité de contrôle compétente (article 77 du RGPD).{' '}
          <br></br>Pour exercer vos droits, veuillez adresser votre courrier à :{' '}
          <br></br>
          Marilène Khieu, 9 rue d'Aubervilliers, 75018 Paris.
        </p>
        <p>
          Afin que le responsable du traitement des données puisse faire droit à
          sa demande, l’utilisateur peut être tenu de lui communiquer certaines
          informations telles que : ses noms et prénoms, son adresse e-mail
          ainsi que son numéro de compte, d’espace personnel ou d’abonné.{' '}
          <br></br>Consultez le site <a href="cnil.fr">cnil.fr</a> pour plus
          d’informations sur vos droits.
        </p>
      </section>

      <section>
        <h2>
          ARTICLE 6 : CONDITIONS DE MODIFICATION DE LA POLITIQUE DE
          CONFIDENTIALITÉ
        </h2>
        <p>
          L’éditeur du site https://mintfarben-studio.com/ se réserve le droit
          de pouvoir modifier la présente Politique à tout moment afin d’assurer
          aux utilisateurs du site sa conformité avec le droit en vigueur.
        </p>
        <p>
          Les éventuelles modifications ne sauraient avoir d’incidence sur les
          achats antérieurement effectués sur le site, lesquels restent soumis à
          la Politique en vigueur au moment de l’achat et telle qu’acceptée par
          l’utilisateur lors de la validation de l’achat.
        </p>
        <p>
          La présente politique, éditée le 29 août 2024, a été mise à jour le 29
          août 2024.
        </p>
      </section>
    </main>
  );
};

export default PrivacyPolicyOrganism;
