import './players-list.scss';
import InputString from '../../atoms/input-string/input-string';
import { defaultInputStringData } from '../../../constants/games-constants';
import Button from '../../atoms/button/button';
import { GameConfig } from '../../../interfaces/games-interfaces';
import { diceSixIcon } from '../../../constants/icons-constants';
import { removeAccentsFromString } from '../../../helpers/common-helpers';
import { ModalScoreCardProps } from '../../../templates/yahtzee-home-template';

export interface PlayersListProps {
  gameConfig: GameConfig;
  setGameConfig: (value: GameConfig) => void;
}

export interface SetSelectedPlayerIdProps {
  setSelectedPlayerId: (value: number) => void;
}

const PlayersList = (
  props: PlayersListProps & ModalScoreCardProps & SetSelectedPlayerIdProps
) => {
  const {
    gameConfig,
    setGameConfig,
    setSelectedPlayerId,
    isScoreCardModalOpen,
    setIsScoreCardModalOpen,
  } = props;

  const displayScoreCard = (player: any) => {
    setIsScoreCardModalOpen(!isScoreCardModalOpen);
    setSelectedPlayerId(player.id);
  };

  return (
    <div>
      <ul>
        {gameConfig.players.map((player, index) => (
          <li key={index} className={`player-item-${index}`}>
            <Button
              className="dice-scoring-buttons-details"
              content={<i className={diceSixIcon} />}
              onClick={() => displayScoreCard(player)}
            />

            <InputString
              inputsData={{
                ...defaultInputStringData,
                id: `${player.id}`,
                className: 'input-string players-inputs',
                type: 'string',
                placeholder: 'Enter a name',
                name: player.name,
                onChange: (e: any) => {
                  const newPlayerNames = [...gameConfig.players];
                  newPlayerNames[index].name = removeAccentsFromString(
                    e.target.value
                  );
                  setGameConfig({ ...gameConfig, players: newPlayerNames });
                },
                value: player.name,
                required: true,
                minLength: 1,
                maxLength: 15,
              }}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PlayersList;
