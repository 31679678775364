import './input-string.scss';
import { InputStringData } from '../../../interfaces/games-interfaces';

interface InputStringProps {
  inputsData: InputStringData;
}

const InputString = (props: InputStringProps) => {
  const { inputsData } = props;

  return (
    <div>
      <input
        id={inputsData.id}
        className={inputsData.className}
        placeholder={inputsData.placeholder}
        type={inputsData.type}
        name={inputsData.name}
        onChange={inputsData.onChange}
        value={inputsData.value}
        required={inputsData.required}
        minLength={inputsData.minLength}
        maxLength={inputsData.maxLength}
      />
    </div>
  );
};

export default InputString;
