import i18next from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

let isInitialized = false;

i18next.use(
  resourcesToBackend(
    (language: string, namespace: string) =>
      import(`./locales/${language}/${namespace}.json`)
  )
);

export const initializeI18NextWithCallBack = (callback: () => void) => {
  if (isInitialized) return;
  i18next
    .init({
      debug: true,
      lng: 'en',
    })
    .then(() => {
      // console.log('done i18next');
      callback();
      isInitialized = true;
    });
};

export default i18next;
