import './button.scss';

export interface ButtonProps {
  className: string;
  content: string | number | JSX.Element;
  onClick: () => void;
  disabled?: boolean;
  title?: string;
}

const Button = (props: ButtonProps) => {
  const { content, onClick, className, disabled, title } = props;

  return (
    <button
      className={className}
      onClick={onClick}
      disabled={disabled ?? false}
      title={title}
    >
      {content}
    </button>
  );
};

export default Button;
