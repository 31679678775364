import { useEffect } from 'react';
import {
  getTranslation,
  removeDuplicatesFromArray,
  troncateString,
} from '../../../helpers/common-helpers';
import {
  CurrentDicesScoringProps,
  CurrentPlayerIdProps,
  GameScoringProps,
  ModalGameEndProps,
  ModalGameInstructionsProps,
  ModalProps,
  ModalScoreCardProps,
  RollNumberProps,
  RoundNumberProps,
  SelectedDicesProps,
  YahtzeeHomeTemplateProps,
} from '../../../templates/yahtzee-home-template';
import ButtonsRulesList from '../../molecules/games/buttons-rules-list';
import PlayersList from '../../molecules/games/players-list';
import Modal from '../../molecules/modal';
import './yahtzee-game-organism.scss';
import {
  closeIcon,
  diceFiveIcon,
  diceFourIcon,
  diceOneIcon,
  diceSixIcon,
  diceThreeIcon,
  diceTwoIcon,
  handPaper,
  handScissors,
  handSpokeIcon,
  handStone,
  pencilIcon,
  regularHandIcon,
  smileyFilledIcon,
} from '../../../constants/icons-constants';
import Button from '../../atoms/button/button';
import {
  CombinationInfos,
  CombinationNames,
  DefaultScoring,
} from '../../../interfaces/games-interfaces';
import {
  numberOfThrowns,
  numberOfRounds,
  defaultGameConfig,
} from '../../../constants/games-constants';
import DicesList from '../../molecules/games/dices-list';
import {
  calculateScoreCombination,
  getScoreOfSpecialCombinations,
  getSubtotalOrTotalScore,
  getSortedDicesResults,
  getSumArrayNumbers,
  getSumOfIdenticalNumbers,
  getHighestStraight,
  determineWinner,
  createDefaultDicesScoring,
} from '../../../helpers/games/games-helpers';

export interface SetSelectedNumbersOfPlayersProps {
  setSelectedNumbersOfPlayers: (value: number) => void;
}

const YahtzeeGameOrganism = (
  props: YahtzeeHomeTemplateProps &
    GameScoringProps &
    CurrentDicesScoringProps &
    SelectedDicesProps &
    CurrentPlayerIdProps &
    SetSelectedNumbersOfPlayersProps &
    RollNumberProps &
    RoundNumberProps &
    ModalProps &
    ModalScoreCardProps &
    ModalGameEndProps &
    ModalGameInstructionsProps
) => {
  const {
    gameState,
    setGameState,
    gameConfig,
    setGameConfig,
    gameScoring,
    setGameScoring,
    currentDicesScoring,
    setCurrentDicesScoring,
    selectedDicesToReroll,
    setSelectedDicesToReroll,
    selectedPlayerId,
    setSelectedPlayerId,
    setSelectedNumbersOfPlayers,
    rollNumber,
    setRollNumber,
    roundNumber,
    setRoundNumber,
    isInstructionsModalOpen,
    setIsInstructionsModalOpen,
    isScoreCardModalOpen,
    setIsScoreCardModalOpen,
    isGameEndContentModalOpen,
    setIsGameEndContentModalOpen,
    isGameInstructionsModalOpen,
    setIsGameInstructionsModalOpen,
  } = props;

  useEffect(() => {
    setIsGameInstructionsModalOpen(true);
    setTimeout(() => setIsGameInstructionsModalOpen(false), 4000);
  }, []);

  useEffect(() => {
    setTimeout(() => setIsInstructionsModalOpen(true), 3000);
  }, [roundNumber, isScoreCardModalOpen, currentDicesScoring]);

  const getPlayerNameWhenClickingOnScoreCard = gameConfig.players.find(
    (player) => player.id === selectedPlayerId
  );

  // Returns score of player on which I clicked/that I selected
  const getSelectedPlayerGameScoring = () =>
    gameScoring.find((player) => player.playerId === selectedPlayerId);

  // Returns playerId + etc. + FinalScore of player CURRENTLY PLAYING (when currentlyPlaying === true)
  const getCurrentPlayingPlayerGameScoring = () =>
    gameScoring.find((player) => player.isCurrentlyPlaying);

  // Returns name and icon of player CURRENTLY PLAYING
  const getNameIconOfCurrentPlayer = gameConfig.players.find(
    (player) => player.id === getCurrentPlayingPlayerGameScoring()?.playerId
  );

  // Returns playerId, thrownNumber, rollNumber, isCurrentlyPlaying and finalScoring of player CURRENTLY PLAYING
  const getGameDataOfSelectedPlayer = () =>
    gameScoring.find(
      (playerScore) =>
        playerScore.playerId === getSelectedPlayerGameScoring()?.playerId
    );

  const finalRecordScoringOfSelectedPlayer =
    getGameDataOfSelectedPlayer()?.finalScoring;

  const gameTurnContent = (
    <>
      <div className="game-turn-header">
        {gameState === 'in game' && (
          <div>
            <div className="paper-scissors-stone-container">
              <i className={handPaper} />
              <i className={handScissors} />
              <i className={handStone} />
            </div>

            <h2>
              <span>{getNameIconOfCurrentPlayer?.name}</span>...{' '}
            </h2>

            {rollNumber === 0 &&
              getTranslation(
                'translation',
                'yahtzee.instructions.roll-dices'
              ).toUpperCase()}
            {rollNumber === 1 &&
              getTranslation('translation', 'yahtzee.instructions.roll-dices') +
                ' ' +
                'or' +
                ' ' +
                getTranslation(
                  'translation',
                  'yahtzee.instructions.select-combination'
                ).toLowerCase()}
            {rollNumber === 2 &&
              getTranslation('translation', 'yahtzee.instructions.last-throw')}
            {rollNumber === 3 &&
              getTranslation(
                'translation',
                'yahtzee.instructions.select-combination'
              )}
          </div>
        )}
      </div>
    </>
  );

  const rollDices = () => {
    const copyOfCurrentScoring: Array<DefaultScoring> = [
      ...currentDicesScoring,
    ];

    const nbOfDicesToReroll = currentDicesScoring.filter(
      (dice) => dice.isSelected === false
    );

    if (nbOfDicesToReroll.length === 5) {
      for (let z = 0; z < nbOfDicesToReroll.length; z++) {
        let newDice = Math.floor(Math.random() * 6 + 1);
        copyOfCurrentScoring[z].score = newDice;
      }
      setCurrentDicesScoring(copyOfCurrentScoring);
      return;
    }

    currentDicesScoring.forEach((dice, index) => {
      if (dice.isSelected === true) {
        let newDice = Math.floor(Math.random() * 6 + 1);
        copyOfCurrentScoring[index].score = newDice;
        copyOfCurrentScoring[index].isSelected = false;
        setCurrentDicesScoring(copyOfCurrentScoring);
        return;
      }
    });
  };

  const pickDicesToReroll = (index: number) => {
    const currentScoringCopy = [...currentDicesScoring];

    currentScoringCopy[index].isSelected =
      !currentScoringCopy[index].isSelected;

    setCurrentDicesScoring(currentScoringCopy);
  };

  const resetDices = () => {
    setCurrentDicesScoring(createDefaultDicesScoring()); // Reset dices at 1 - 2 - 3 - 4 - 5
  };

  const incrementThrownByOne = () => {
    setRollNumber(rollNumber + 1);
  };

  const rollDiceActions = () => {
    rollDices();
    incrementThrownByOne();
    roundNumber < 14 ? setIsScoreCardModalOpen(true) : '';
  };

  const displayNextTurnPlayerModal = () => {
    setTimeout(() => setIsInstructionsModalOpen(true), 1000);
  };

  const assignResultsToScoreCard = (
    playerId: number,
    combinationName: CombinationNames,
    diceResults: number
  ) => {
    let copyGameScoring = [...gameScoring];

    let combinationInfos: CombinationInfos | undefined =
      copyGameScoring[playerId].finalScoring[combinationName];

    if (combinationInfos) {
      combinationInfos.score = diceResults;
      combinationInfos.isAssigned = true;
    }
    setGameScoring(copyGameScoring);
  };

  const endGame = () => {
    setGameState('ended');
    setRoundNumber(gameConfig.maxNumberOfTurns);
  };

  const getWinnerOrWinnersIdAndScore = determineWinner(gameScoring);

  const getWinnerOrWinnersNameAndIcon = gameConfig.players.filter((player) =>
    getWinnerOrWinnersIdAndScore.some((winner) => winner.playerId === player.id)
  );

  const maxScore = getWinnerOrWinnersIdAndScore[0].totalScoring;

  const updateGameStatusAtTheEndOfPlayerTour = (
    playerId: number,
    combinationName: CombinationNames,
    diceResults: number
  ) => {
    let nextPlayerId = playerId + 1;
    let nextRoundNumber: number = roundNumber + 1;
    // let nextRoundNumber: number = 14;
    assignResultsToScoreCard(playerId, combinationName, diceResults);
    displayNextTurnPlayerModal();
    setTimeout(() => setIsScoreCardModalOpen(false), 300);
    resetDices();

    if (nextPlayerId >= gameConfig.numberOfPlayers) {
      setRoundNumber(nextRoundNumber);
      nextPlayerId = 0;
    }

    if (nextPlayerId === 0 && nextRoundNumber > gameConfig.maxNumberOfTurns) {
      endGame();
      setIsGameInstructionsModalOpen(true);
      return;
    }

    setRollNumber(0);
    gameScoring[nextPlayerId].isCurrentlyPlaying = true;
    gameScoring[playerId].isCurrentlyPlaying = false;
    setTimeout(() => setSelectedPlayerId(nextPlayerId), 500);
  };

  const scoreCardContent = (
    <>
      <div className="score-card-header">
        <h1>
          {getTranslation(
            'translation',
            'yahtzee.titles.score-card'
          ).toUpperCase()}{' '}
          -{' '}
          <span className="player-playing-name">
            {getPlayerNameWhenClickingOnScoreCard?.name}
          </span>
        </h1>
        <Button
          className="close-button close-button-yahtzee"
          content={<i className={closeIcon} />}
          onClick={() => setIsScoreCardModalOpen(false)}
          key={undefined}
          disabled={false}
        />
      </div>

      <table className="score-card-table">
        {/* header */}
        <thead>
          <tr>
            <th>
              {getTranslation(
                'translation',
                'yahtzee.score-card-table.combinations'
              )}
            </th>
            <th>
              {getTranslation('translation', 'yahtzee.score-card-table.points')}
            </th>
            <th>
              {getTranslation('translation', 'yahtzee.score-card-table.score')}
            </th>
          </tr>
        </thead>

        <tbody>
          {/* ONES */}
          <tr>
            <td>
              <i className={diceOneIcon} />{' '}
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.0.ones'
              ).toUpperCase()}
            </td>
            <td>
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.0.points'
              )}
            </td>
            <td>
              {(roundNumber === 1 &&
                rollNumber === 0 &&
                getGameDataOfSelectedPlayer()?.isCurrentlyPlaying === true) ||
              getGameDataOfSelectedPlayer()?.finalScoring.Ones.isAssigned ||
              getGameDataOfSelectedPlayer()?.playerId !==
                getCurrentPlayingPlayerGameScoring()?.playerId ? (
                getGameDataOfSelectedPlayer()?.finalScoring.Ones.score
              ) : (
                <Button
                  className="dices-result dices-result-to-confirm"
                  title={getTranslation(
                    'translation',
                    'yahtzee.button-titles.keep-value'
                  )}
                  content={calculateScoreCombination(
                    getSumOfIdenticalNumbers,
                    getSortedDicesResults(currentDicesScoring),
                    1
                  )}
                  onClick={() =>
                    updateGameStatusAtTheEndOfPlayerTour(
                      getSelectedPlayerGameScoring()?.playerId ?? 0,
                      getGameDataOfSelectedPlayer()?.finalScoring.Ones.name ??
                        'Ones',
                      calculateScoreCombination(
                        getSumOfIdenticalNumbers,
                        getSortedDicesResults(currentDicesScoring),
                        1
                      )
                    )
                  }
                />
              )}
            </td>
          </tr>

          {/* TWOS */}
          <tr>
            <td>
              <i className={diceTwoIcon} />{' '}
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.1.twos'
              ).toUpperCase()}{' '}
            </td>
            <td>
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.1.points'
              )}
            </td>
            <td>
              {(roundNumber === 1 &&
                rollNumber === 0 &&
                getGameDataOfSelectedPlayer()?.isCurrentlyPlaying === true) ||
              getGameDataOfSelectedPlayer()?.finalScoring.Twos.isAssigned ||
              getGameDataOfSelectedPlayer()?.playerId !==
                getCurrentPlayingPlayerGameScoring()?.playerId ? (
                getGameDataOfSelectedPlayer()?.finalScoring.Twos.score
              ) : (
                <Button
                  className="dices-result dices-result-to-confirm"
                  title={getTranslation(
                    'translation',
                    'yahtzee.button-titles.keep-value'
                  )}
                  content={calculateScoreCombination(
                    getSumOfIdenticalNumbers,
                    getSortedDicesResults(currentDicesScoring),
                    2
                  )}
                  onClick={() =>
                    updateGameStatusAtTheEndOfPlayerTour(
                      getSelectedPlayerGameScoring()?.playerId ?? 0,
                      getGameDataOfSelectedPlayer()?.finalScoring.Twos.name ??
                        'Ones',
                      calculateScoreCombination(
                        getSumOfIdenticalNumbers,
                        getSortedDicesResults(currentDicesScoring),
                        2
                      )
                    )
                  }
                />
              )}
            </td>
          </tr>

          {/* THREES */}
          <tr>
            <td>
              <i className={diceThreeIcon} />{' '}
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.2.threes'
              ).toUpperCase()}
            </td>
            <td>
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.2.points'
              )}
            </td>
            <td>
              {(roundNumber === 1 &&
                rollNumber === 0 &&
                getGameDataOfSelectedPlayer()?.isCurrentlyPlaying === true) ||
              getGameDataOfSelectedPlayer()?.finalScoring.Threes.isAssigned ||
              getGameDataOfSelectedPlayer()?.playerId !==
                getCurrentPlayingPlayerGameScoring()?.playerId ? (
                getGameDataOfSelectedPlayer()?.finalScoring.Threes.score
              ) : (
                <Button
                  className="dices-result dices-result-to-confirm"
                  title={getTranslation(
                    'translation',
                    'yahtzee.button-titles.keep-value'
                  )}
                  content={calculateScoreCombination(
                    getSumOfIdenticalNumbers,
                    getSortedDicesResults(currentDicesScoring),
                    3
                  )}
                  onClick={() =>
                    updateGameStatusAtTheEndOfPlayerTour(
                      getSelectedPlayerGameScoring()?.playerId ?? 0,
                      getGameDataOfSelectedPlayer()?.finalScoring.Threes.name ??
                        'Ones',
                      calculateScoreCombination(
                        getSumOfIdenticalNumbers,
                        getSortedDicesResults(currentDicesScoring),
                        3
                      )
                    )
                  }
                />
              )}
            </td>
          </tr>

          {/* FOURS */}
          <tr>
            <td>
              <i className={diceFourIcon} />{' '}
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.3.fours'
              ).toUpperCase()}
            </td>
            <td>
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.3.points'
              )}
            </td>
            <td>
              {(roundNumber === 1 &&
                rollNumber === 0 &&
                getGameDataOfSelectedPlayer()?.isCurrentlyPlaying === true) ||
              getGameDataOfSelectedPlayer()?.finalScoring.Fours.isAssigned ||
              getGameDataOfSelectedPlayer()?.playerId !==
                getCurrentPlayingPlayerGameScoring()?.playerId ? (
                getGameDataOfSelectedPlayer()?.finalScoring.Fours.score
              ) : (
                <Button
                  className="dices-result dices-result-to-confirm"
                  title={getTranslation(
                    'translation',
                    'yahtzee.button-titles.keep-value'
                  )}
                  content={calculateScoreCombination(
                    getSumOfIdenticalNumbers,
                    getSortedDicesResults(currentDicesScoring),
                    4
                  )}
                  onClick={() =>
                    updateGameStatusAtTheEndOfPlayerTour(
                      getSelectedPlayerGameScoring()?.playerId ?? 0,
                      getGameDataOfSelectedPlayer()?.finalScoring.Fours.name ??
                        'Ones',
                      calculateScoreCombination(
                        getSumOfIdenticalNumbers,
                        getSortedDicesResults(currentDicesScoring),
                        4
                      )
                    )
                  }
                />
              )}
            </td>
          </tr>

          {/* FIVES */}
          <tr>
            <td>
              <i className={diceFiveIcon} />{' '}
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.4.fives'
              ).toUpperCase()}
            </td>
            <td>
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.4.points'
              )}
            </td>
            <td>
              {(roundNumber === 1 &&
                rollNumber === 0 &&
                getGameDataOfSelectedPlayer()?.isCurrentlyPlaying === true) ||
              getGameDataOfSelectedPlayer()?.finalScoring.Fives.isAssigned ||
              getGameDataOfSelectedPlayer()?.playerId !==
                getCurrentPlayingPlayerGameScoring()?.playerId ? (
                getGameDataOfSelectedPlayer()?.finalScoring.Fives.score
              ) : (
                <Button
                  className="dices-result dices-result-to-confirm"
                  title={getTranslation(
                    'translation',
                    'yahtzee.button-titles.keep-value'
                  )}
                  content={calculateScoreCombination(
                    getSumOfIdenticalNumbers,
                    getSortedDicesResults(currentDicesScoring),
                    5
                  )}
                  onClick={() =>
                    updateGameStatusAtTheEndOfPlayerTour(
                      getSelectedPlayerGameScoring()?.playerId ?? 0,
                      getGameDataOfSelectedPlayer()?.finalScoring.Fives.name ??
                        'Ones',
                      calculateScoreCombination(
                        getSumOfIdenticalNumbers,
                        getSortedDicesResults(currentDicesScoring),
                        5
                      )
                    )
                  }
                />
              )}
            </td>
          </tr>

          {/* SIXES */}
          <tr>
            <td>
              <i className={diceSixIcon} />{' '}
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.5.sixes'
              ).toUpperCase()}
            </td>
            <td>
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.5.points'
              )}
            </td>
            <td>
              {(roundNumber === 1 &&
                rollNumber === 0 &&
                getGameDataOfSelectedPlayer()?.isCurrentlyPlaying === true) ||
              getGameDataOfSelectedPlayer()?.finalScoring.Sixes.isAssigned ||
              getGameDataOfSelectedPlayer()?.playerId !==
                getCurrentPlayingPlayerGameScoring()?.playerId ? (
                getGameDataOfSelectedPlayer()?.finalScoring.Sixes.score
              ) : (
                <Button
                  className="dices-result dices-result-to-confirm"
                  title={getTranslation(
                    'translation',
                    'yahtzee.button-titles.keep-value'
                  )}
                  content={calculateScoreCombination(
                    getSumOfIdenticalNumbers,
                    getSortedDicesResults(currentDicesScoring),
                    6
                  )}
                  onClick={() =>
                    updateGameStatusAtTheEndOfPlayerTour(
                      getSelectedPlayerGameScoring()?.playerId ?? 0,
                      getGameDataOfSelectedPlayer()?.finalScoring.Sixes.name ??
                        'Ones',
                      calculateScoreCombination(
                        getSumOfIdenticalNumbers,
                        getSortedDicesResults(currentDicesScoring),
                        6
                      )
                    )
                  }
                />
              )}
            </td>
          </tr>

          {/* BONUS */}
          <tr className="bonus">
            <td>
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.13.bonus'
              ).toUpperCase()}{' '}
              <span className="">(if subtotal {'>'} 63 points)</span>
            </td>
            <td>
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.13.points'
              )}{' '}
              points
            </td>
            <td>
              {getGameDataOfSelectedPlayer()?.finalScoring.Bonus.isAssigned
                ? getGameDataOfSelectedPlayer()?.finalScoring.Bonus.score
                : 0}
            </td>
          </tr>

          {/* subtotal */}
          <tr className="subtotal">
            <td>
              {getTranslation(
                'translation',
                'yahtzee.score-card-table.subtotal'
              ).toUpperCase()}
            </td>
            <td></td>
            <td>
              {finalRecordScoringOfSelectedPlayer &&
                getSubtotalOrTotalScore(
                  finalRecordScoringOfSelectedPlayer,
                  'one',
                  roundNumber,
                  gameConfig.maxNumberOfTurns
                )}
            </td>
          </tr>

          {/* THREE OF A KIND */}
          <tr>
            <td>
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.6.three-of-a-kind'
              ).toUpperCase()}
            </td>
            <td>
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.6.points'
              )}
            </td>
            <td>
              {(roundNumber === 1 &&
                rollNumber === 0 &&
                getGameDataOfSelectedPlayer()?.isCurrentlyPlaying === true) ||
              getGameDataOfSelectedPlayer()?.finalScoring.ThreeOfAKind
                .isAssigned ||
              getGameDataOfSelectedPlayer()?.playerId !==
                getCurrentPlayingPlayerGameScoring()?.playerId ? (
                getGameDataOfSelectedPlayer()?.finalScoring.ThreeOfAKind.score
              ) : (
                <Button
                  className="dices-result dices-result-to-confirm"
                  title={getTranslation(
                    'translation',
                    'yahtzee.button-titles.keep-value'
                  )}
                  content={
                    calculateScoreCombination(
                      getScoreOfSpecialCombinations,
                      getSortedDicesResults(currentDicesScoring),
                      3
                    ) ?? '0'
                  }
                  onClick={() =>
                    updateGameStatusAtTheEndOfPlayerTour(
                      getSelectedPlayerGameScoring()?.playerId ?? 0,
                      getGameDataOfSelectedPlayer()?.finalScoring.ThreeOfAKind
                        .name ?? 'Ones',
                      calculateScoreCombination(
                        getSumArrayNumbers,
                        getSortedDicesResults(currentDicesScoring),
                        3
                      )
                    )
                  }
                />
              )}
            </td>
          </tr>

          {/* FOUR OF A KIND */}
          <tr>
            <td>
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.7.four-of-a-kind'
              ).toUpperCase()}
            </td>
            <td>
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.7.points'
              )}
            </td>
            <td>
              {(roundNumber === 1 &&
                rollNumber === 0 &&
                getGameDataOfSelectedPlayer()?.isCurrentlyPlaying === true) ||
              getGameDataOfSelectedPlayer()?.finalScoring.FourOfAKind
                .isAssigned ||
              getGameDataOfSelectedPlayer()?.playerId !==
                getCurrentPlayingPlayerGameScoring()?.playerId ? (
                getGameDataOfSelectedPlayer()?.finalScoring.FourOfAKind.score
              ) : (
                <Button
                  className="dices-result dices-result-to-confirm"
                  title={getTranslation(
                    'translation',
                    'yahtzee.button-titles.keep-value'
                  )}
                  content={
                    calculateScoreCombination(
                      getScoreOfSpecialCombinations,
                      getSortedDicesResults(currentDicesScoring),
                      4
                    ) ?? '0'
                  }
                  onClick={() =>
                    updateGameStatusAtTheEndOfPlayerTour(
                      getSelectedPlayerGameScoring()?.playerId ?? 0,
                      getGameDataOfSelectedPlayer()?.finalScoring.FourOfAKind
                        .name ?? 'Ones',
                      calculateScoreCombination(
                        getSumArrayNumbers,
                        getSortedDicesResults(currentDicesScoring),
                        4
                      )
                    )
                  }
                />
              )}
            </td>
          </tr>

          {/* SMALL STRAIGHT */}
          <tr>
            <td>
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.8.small-straight'
              ).toUpperCase()}
            </td>
            <td>
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.8.points'
              )}{' '}
              points
            </td>
            <td>
              {(roundNumber === 1 &&
                rollNumber === 0 &&
                getGameDataOfSelectedPlayer()?.isCurrentlyPlaying === true) ||
              getGameDataOfSelectedPlayer()?.finalScoring.SmallStraight
                .isAssigned ||
              getGameDataOfSelectedPlayer()?.playerId !==
                getCurrentPlayingPlayerGameScoring()?.playerId ? (
                getGameDataOfSelectedPlayer()?.finalScoring.SmallStraight.score
              ) : (
                <Button
                  className="dices-result dices-result-to-confirm"
                  title={getTranslation(
                    'translation',
                    'yahtzee.button-titles.keep-value'
                  )}
                  content={getHighestStraight(
                    getSortedDicesResults(currentDicesScoring),
                    4
                  )}
                  onClick={() =>
                    updateGameStatusAtTheEndOfPlayerTour(
                      getSelectedPlayerGameScoring()?.playerId ?? 0,
                      getGameDataOfSelectedPlayer()?.finalScoring.SmallStraight
                        .name ?? 'Ones',
                      getHighestStraight(
                        getSortedDicesResults(currentDicesScoring),
                        4
                      )
                    )
                  }
                />
              )}
            </td>
          </tr>

          {/* LARGE STRAIGHT */}
          <tr>
            <td>
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.9.large-straight'
              ).toUpperCase()}
            </td>
            <td>
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.9.points'
              )}{' '}
              points
            </td>
            <td>
              {(roundNumber === 1 &&
                rollNumber === 0 &&
                getGameDataOfSelectedPlayer()?.isCurrentlyPlaying === true) ||
              getGameDataOfSelectedPlayer()?.finalScoring.LargeStraight
                .isAssigned ||
              getGameDataOfSelectedPlayer()?.playerId !==
                getCurrentPlayingPlayerGameScoring()?.playerId ? (
                getGameDataOfSelectedPlayer()?.finalScoring.LargeStraight.score
              ) : (
                <Button
                  className="dices-result dices-result-to-confirm"
                  title={getTranslation(
                    'translation',
                    'yahtzee.button-titles.keep-value'
                  )}
                  content={getHighestStraight(
                    getSortedDicesResults(currentDicesScoring),
                    5
                  )}
                  onClick={() =>
                    updateGameStatusAtTheEndOfPlayerTour(
                      getSelectedPlayerGameScoring()?.playerId ?? 0,
                      getGameDataOfSelectedPlayer()?.finalScoring.LargeStraight
                        .name ?? 'Ones',
                      getHighestStraight(
                        getSortedDicesResults(currentDicesScoring),
                        5
                      )
                    )
                  }
                />
              )}
            </td>
          </tr>

          {/* FULL HOUSE */}
          <tr>
            <td>
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.10.full-house'
              ).toUpperCase()}
            </td>
            <td>
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.10.points'
              )}{' '}
              points
            </td>
            <td>
              {(roundNumber === 1 &&
                rollNumber === 0 &&
                getGameDataOfSelectedPlayer()?.isCurrentlyPlaying === true) ||
              getGameDataOfSelectedPlayer()?.finalScoring.FullHouse
                .isAssigned ||
              getGameDataOfSelectedPlayer()?.playerId !==
                getCurrentPlayingPlayerGameScoring()?.playerId ? (
                getGameDataOfSelectedPlayer()?.finalScoring.FullHouse.score
              ) : (
                <Button
                  className="dices-result dices-result-to-confirm"
                  title={getTranslation(
                    'translation',
                    'yahtzee.button-titles.keep-value'
                  )}
                  content={
                    getScoreOfSpecialCombinations(
                      getSortedDicesResults(currentDicesScoring),
                      5,
                      getGameDataOfSelectedPlayer()?.finalScoring.FullHouse.name
                    ) ?? '0'
                  }
                  onClick={() =>
                    updateGameStatusAtTheEndOfPlayerTour(
                      getSelectedPlayerGameScoring()?.playerId ?? 0,
                      getGameDataOfSelectedPlayer()?.finalScoring.FullHouse
                        .name ?? 'Ones',
                      getScoreOfSpecialCombinations(
                        getSortedDicesResults(currentDicesScoring),
                        5,
                        getGameDataOfSelectedPlayer()?.finalScoring.FullHouse
                          .name
                      )
                    )
                  }
                />
              )}
            </td>
          </tr>

          {/* CHANCE */}
          <tr>
            <td>
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.11.chance'
              ).toUpperCase()}
            </td>
            <td>
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.11.points'
              )}
            </td>
            <td>
              {(roundNumber === 1 &&
                rollNumber === 0 &&
                getGameDataOfSelectedPlayer()?.isCurrentlyPlaying === true) ||
              getGameDataOfSelectedPlayer()?.finalScoring.Chance.isAssigned ||
              getGameDataOfSelectedPlayer()?.playerId !==
                getCurrentPlayingPlayerGameScoring()?.playerId ? (
                getGameDataOfSelectedPlayer()?.finalScoring.Chance.score
              ) : (
                <Button
                  className="dices-result dices-result-to-confirm"
                  title={getTranslation(
                    'translation',
                    'yahtzee.button-titles.keep-value'
                  )}
                  content={calculateScoreCombination(
                    getSumArrayNumbers,
                    getSortedDicesResults(currentDicesScoring),
                    0
                  )}
                  onClick={() =>
                    updateGameStatusAtTheEndOfPlayerTour(
                      getSelectedPlayerGameScoring()?.playerId ?? 0,
                      getGameDataOfSelectedPlayer()?.finalScoring.Chance.name ??
                        'Ones',
                      calculateScoreCombination(
                        getSumArrayNumbers,
                        getSortedDicesResults(currentDicesScoring),
                        0
                      )
                    )
                  }
                />
              )}
            </td>
          </tr>

          {/* YAHTZEE */}
          <tr>
            <td>
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.12.yahtzee'
              ).toUpperCase()}
            </td>
            <td>
              {getTranslation(
                'translation',
                'yahtzee.yahtzee-combinations.12.points'
              )}{' '}
              points
            </td>
            <td>
              {(roundNumber === 1 &&
                rollNumber === 0 &&
                getGameDataOfSelectedPlayer()?.isCurrentlyPlaying === true) ||
              getGameDataOfSelectedPlayer()?.finalScoring.Yahtzee.isAssigned ||
              getGameDataOfSelectedPlayer()?.playerId !==
                getCurrentPlayingPlayerGameScoring()?.playerId ? (
                getGameDataOfSelectedPlayer()?.finalScoring.Yahtzee.score
              ) : (
                <Button
                  className="dices-result dices-result-to-confirm"
                  title={getTranslation(
                    'translation',
                    'yahtzee.button-titles.keep-value'
                  )}
                  content={
                    getScoreOfSpecialCombinations(
                      getSortedDicesResults(currentDicesScoring),
                      5,
                      getGameDataOfSelectedPlayer()?.finalScoring.Yahtzee.name
                    ) ?? '0'
                  }
                  onClick={() =>
                    updateGameStatusAtTheEndOfPlayerTour(
                      getSelectedPlayerGameScoring()?.playerId ?? 0,
                      getGameDataOfSelectedPlayer()?.finalScoring.Yahtzee
                        .name ?? 'Ones',
                      getScoreOfSpecialCombinations(
                        getSortedDicesResults(currentDicesScoring),
                        5,
                        getGameDataOfSelectedPlayer()?.finalScoring.Yahtzee.name
                      )
                    )
                  }
                />
              )}
            </td>
          </tr>

          {/* subtotal */}
          <tr className="subtotal">
            <td>
              {getTranslation(
                'translation',
                'yahtzee.score-card-table.subtotal'
              ).toUpperCase()}
            </td>
            <td></td>
            <td>
              {finalRecordScoringOfSelectedPlayer &&
                getSubtotalOrTotalScore(
                  finalRecordScoringOfSelectedPlayer,
                  'two',
                  roundNumber,
                  gameConfig.maxNumberOfTurns
                )}
            </td>
          </tr>

          {/* total */}
          <tr className="total">
            <td>
              {getTranslation(
                'translation',
                'yahtzee.score-card-table.total'
              ).toUpperCase()}
            </td>
            <td></td>
            <td>
              {finalRecordScoringOfSelectedPlayer &&
                getSubtotalOrTotalScore(
                  finalRecordScoringOfSelectedPlayer,
                  'total',
                  roundNumber,
                  gameConfig.maxNumberOfTurns
                )}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );

  const gameStatusContent = (
    <>
      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <span className="big-letters-font player-currently-playing-name">
                {troncateString(getNameIconOfCurrentPlayer?.name ?? '', 8)}
              </span>
            </td>
            <td>
              's{' '}
              {getTranslation(
                'translation',
                'yahtzee.content.turn'
              ).toLowerCase()}
            </td>
          </tr>
          <tr>
            <td>
              <span className="big-letters-font">{rollNumber}</span>
            </td>
            <td>
              / {numberOfThrowns}{' '}
              {getTranslation(
                'translation',
                'yahtzee.content.throwns'
              ).toLowerCase()}
            </td>
          </tr>
          <tr>
            <td>
              <span className="big-letters-font">{roundNumber}</span>
            </td>
            <td>
              / {numberOfRounds}{' '}
              {getTranslation(
                'translation',
                'yahtzee.content.rounds'
              ).toLowerCase()}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );

  const resetGameConfig = () => {
    setGameState('inactive');
    setGameConfig({ ...defaultGameConfig, players: [] });
    setSelectedNumbersOfPlayers(0);
  };

  const gameEndContent = (
    <div className="info-content-modal game-end-content">
      <Button
        className="close-button close-button-game-turn-yahtzee"
        content={<i className={closeIcon} />}
        onClick={() => setIsGameInstructionsModalOpen(false)}
        key={undefined}
        disabled={false}
      />
      <p>
        With <span className="winner-total-score">{maxScore} points,</span>
      </p>

      {getWinnerOrWinnersNameAndIcon.length > 1 ? (
        <p>
          the winners are...
          <ul>
            {getWinnerOrWinnersNameAndIcon.map((winner, index) => (
              <li key={index}>
                <span className="winner-name">{winner.name}</span>
              </li>
            ))}
          </ul>
        </p>
      ) : (
        <p>
          the winner is...
          <span className="winner-name">
            {getWinnerOrWinnersNameAndIcon[0].name}
          </span>
        </p>
      )}
      <p className="text-bold">
        {getTranslation(
          'translation',
          'yahtzee.instructions.congratulations'
        ).toUpperCase()}{' '}
        <i className={handSpokeIcon} />!
      </p>
      <Button
        className="plain-button nb-players-not-selected-buttons replay-button"
        content={
          <>
            <i className={smileyFilledIcon} />
            {'  '}
            {getTranslation(
              'translation',
              'yahtzee.buttons.play-again'
            ).toUpperCase()}
          </>
        }
        onClick={() => resetGameConfig()}
        key={undefined}
        disabled={false}
      />
    </div>
  );

  const lastRoundContent = (
    <div className="info-content-modal game-end-content">
      <Button
        className="close-button close-button-game-turn-yahtzee"
        content={<i className={closeIcon} />}
        onClick={() => setIsGameInstructionsModalOpen(false)}
        key={undefined}
        disabled={false}
      />
      <p>
        <i className={regularHandIcon} />{' '}
        {getTranslation('translation', 'yahtzee.instructions.last-round')}
      </p>
    </div>
  );

  const instructionModal = (
    modalClass: string,
    modalContent: JSX.Element,
    useBlur: boolean
  ) => {
    if (useBlur)
      return (
        <div className="game-end-modal-background">
          <Modal
            className={'game-info-modal ' + modalClass}
            content={modalContent}
          />
        </div>
      );
    return (
      <Modal
        className={'game-info-modal ' + modalClass}
        content={modalContent}
      />
    );
  };

  const gameInformationContent = (
    <div className="rename-player-content">
      <Button
        className="close-button close-button-game-turn-yahtzee"
        content={<i className={closeIcon} />}
        onClick={() => setIsGameInstructionsModalOpen(false)}
        key={undefined}
        disabled={false}
      />
      <div className="rename-player-description">
        <i className={pencilIcon} />
        <p>
          {getTranslation(
            'translation',
            'yahtzee.instructions.rename-players-name'
          )}
        </p>
      </div>

      <Button
        className="plain-button ok-button"
        content={getTranslation('translation', 'button.ok')}
        onClick={() => setIsGameInstructionsModalOpen(false)}
        key={undefined}
        disabled={false}
      />
    </div>
  );

  let modalClass = '';
  let modalContent: JSX.Element | undefined = undefined;
  let useBlurBackground = false;

  if (isGameInstructionsModalOpen) {
    if (gameState === 'ended') {
      modalClass = 'game-end-modal';
      modalContent = gameEndContent;
      useBlurBackground = true;
    } else if (roundNumber === 12) {
      modalClass = 'last-round-modal';
      modalContent = lastRoundContent;
    } else {
      modalClass = 'rename-player-modal';
      modalContent = gameInformationContent;
    }
  }

  return (
    <div id="yahtzee-game-organism-container">
      <PlayersList
        gameConfig={gameConfig}
        setGameConfig={setGameConfig}
        isScoreCardModalOpen={isScoreCardModalOpen}
        setIsScoreCardModalOpen={setIsScoreCardModalOpen}
        setSelectedPlayerId={setSelectedPlayerId}
      />

      <Modal className="game-status-modal" content={gameStatusContent} />

      {isInstructionsModalOpen && (
        <Modal className="game-turn-info-modal" content={gameTurnContent} />
      )}

      <DicesList
        currentDicesScoring={currentDicesScoring}
        setCurrentDicesScoring={setCurrentDicesScoring}
        rollDices={rollDices}
        pickDicesToReroll={pickDicesToReroll}
        rollDiceActions={rollDiceActions}
        rollNumber={rollNumber}
        currentPlayingPlayerId={
          getCurrentPlayingPlayerGameScoring()?.playerId ?? 0
        }
        roundNumber={roundNumber}
        maxNumberOfThrowns={gameConfig.maxNumberOfThrowns}
      />

      {modalContent &&
        instructionModal(modalClass, modalContent, useBlurBackground)}

      <div className="score-card-container">
        {isScoreCardModalOpen && (
          <Modal className="score-card-modal" content={scoreCardContent} />
        )}
      </div>

      <ButtonsRulesList
        setGameState={setGameState}
        setGameConfig={setGameConfig}
        setSelectedNumbersOfPlayers={setSelectedNumbersOfPlayers}
        resetGameConfig={resetGameConfig}
      />
    </div>
  );
};

export default YahtzeeGameOrganism;
