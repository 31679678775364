import { footerElementsData } from '../constants/showcase-website.constants';
import { DefaultNavbarElements } from '../interfaces/showcase-website-interfaces';
import Button from '../components/atoms/button/button';
import Footer from '../components/molecules/footer';
import Navbar from '../components/molecules/navbar';
import Icon from '../components/atoms/icon/icon';

interface LayoutTemplateProps {
  logoContent: string | JSX.Element;
  navItemIdClicked: Array<DefaultNavbarElements>;
  setNavItemIdClicked: (value: Array<DefaultNavbarElements>) => void;
  children: JSX.Element;
}

const LayoutTemplate = (props: LayoutTemplateProps) => {
  const { logoContent, navItemIdClicked, setNavItemIdClicked, children } =
    props;

  return (
    <div id="main-body">
      <Navbar
        logoContent={logoContent}
        navItemIdClicked={navItemIdClicked}
        setNavItemIdClicked={setNavItemIdClicked}
      />

      {children}

      <div className="arrow-up-button-container">
        <a href="#studio" className="up-arrow">
          <Button
            className="back-button up-button"
            content={<Icon className="fas fa-arrow-up" />}
            onClick={() => {}}
          />
        </a>
      </div>

      <Footer footerElementsData={footerElementsData} />
    </div>
  );
};

export default LayoutTemplate;
