const TermsConditionsOrganism = () => {
  return (
    <main id="terms-conditions" className="section-standard-padding-left-right">
      <h1>Mentions légales</h1>
      <section>
        <h2>Édition du site Internet</h2>
        <p>
          Le site mintfarben-studio.com (ci-après « le Site »), accessible à
          l’adresse https://mintfarben-studio.com/ est édité par mintfarben.
          Studio EI (ci-après « l’Editeur »), dont le domicile est situé à Paris
          (mintfarben.studio@gmail.com). La société est immatriculée sous le
          numéro SIRET 98768836300017 et est domiciliée à Paris. Code APE :
          7311Z.
        </p>
      </section>

      <section>
        <h2>Rédaction du site Internet</h2>
        <p>Directeur de la publication : Marilène Khieu.</p>
        <p>Contact : mintfarben.studio@gmail.com - 07.67.62.08.77</p>
      </section>

      <section>
        <h2>Rédaction du site Internet</h2>
        <p>
          Le site SITE est hébergé par HOSTINGER operations, UAB dont le siège
          social est situé à Švitrigailos str. 34, Vilnius 03230 Lithuania,
          joignable par le moyen suivant :{' '}
          <a href="mailto:domains@hostinger.com">domains@hostinger.com</a> -
          +37064503378.
        </p>
      </section>

      <section>
        <h2>Respect de la propriété intellectuelle</h2>
        <p>
          Toutes les marques, photographies, textes, commentaires,
          illustrations, images animées ou non, séquences vidéo, sons, ainsi que
          toutes les applications informatiques qui pourraient être utilisées
          pour faire fonctionner le Site et plus généralement tous les éléments
          reproduits ou utilisés sur le Site sont protégés par les lois en
          vigueur au titre de la propriété intellectuelle. Ils sont la propriété
          pleine et entière de l’Editeur ou de ses partenaires, sauf mentions
          particulières. Toute reproduction, représentation, utilisation ou
          adaptation, sous quelque forme que ce soit, de tout ou partie de ces
          éléments, y compris les applications informatiques, sans l’accord
          préalable et écrit de l’Editeur, sont strictement interdites. Le fait
          pour l’Editeur de ne pas engager de procédure dès la prise de
          connaissance de ces utilisations non autorisées ne vaut pas
          acceptation desdites utilisations et renonciation aux poursuites.
          Seule l’utilisation pour un usage privé dans un cercle de famille est
          autorisée et toute autre utilisation est constitutive de contrefaçon
          et/ou d’atteinte aux droits voisins, sanctionnées par Code de la
          propriété intellectuelle. La reprise de tout ou partie de ce contenu
          nécessite l’autorisation préalable de l’Editeur ou du titulaire des
          droits sur ce contenu.
        </p>
      </section>

      <section>
        <h2>Liens hypertextes</h2>
        <p>
          Le Site peut contenir des liens hypertexte donnant accès à d’autres
          sites web édités et gérés par des tiers et non par l’Editeur.
          L’Editeur ne pourra être tenu responsable directement ou indirectement
          dans le cas où lesdits sites tiers ne respecteraient pas les
          dispositions légales.
        </p>
      </section>
    </main>
  );
};

export default TermsConditionsOrganism;
