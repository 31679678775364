import { InputData } from "../interfaces/list-order-interfaces";

export const defaultInputNumberData:InputData = {
  id: '',
  min: 0,
  max: 99,
  type: 'number',
  name: 'input',
  value: 0,
  onChange: function (e: any): void {
    throw new Error('Function not implemented.');
  }
}