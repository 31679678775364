import i18next from '../i18n';

export const getTranslation = (namespace: string, field: string) =>
  i18next.t(`${namespace}:${field}`);

export const createArrayFromRange = (
  start: number,
  stop: number,
  step: number = 1
) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
  );

export const removeDuplicatesFromArray = (array: Array<number>) => array.filter((number, index) => array.indexOf(number) === index);

export const troncateString = (word: string, maxLength: number) => {
  let truncatedWord: string = '';
  let splittedWordLetters: Array<string> = word.split('');

  if (splittedWordLetters.length > maxLength) {
    for (let i = 0; i < maxLength; i++) {
      truncatedWord += splittedWordLetters[i];
    }

    return truncatedWord;
  }

  return word;
};

export const removeAccentsFromString = (word: string) => word.normalize('NFD').replace(/[\u0300-\u036f]/g, '');


