export const addInput = (
  inputs: Array<number>,
  setInputs: (value: Array<number>) => void
) => {
  const newInput = [...inputs];
  newInput.push(Math.floor(Math.random() * 99));
  setInputs(newInput);
};

export const deleteAllInputs = (setInputs: (value: Array<number>) => void) => {
  setInputs([]);
};

export const orderInputs = (
  array: Array<number>, 
  setInputs: (value: Array<number>) => void
  ) => {
  const arrayCopy = [...array];
  let ascendingSortedArray = arrayCopy.sort((a, b) => a - b);
  setInputs(ascendingSortedArray);
};
